.input-warning {
  border: 1px solid map-get($colors, danger) !important;
  &:focus {
    border-color: map-get($colors, danger) !important;
  }
}
.msg-error {
  @include pxRem(bottom, -42);
  @include pxRem(font-size, 14);
  color: map-get($colors, danger);
  position: absolute;
  right: 0;
}
