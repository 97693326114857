.survey-result-page {
  margin: 0 -4px;
  &.web {
    .progress {
      margin-top: 32px;
      padding-top: 13px;
    }
    .page-content {
      padding-top: 129px;
      &.more-spacing {
        padding-top: 134px;
      }
    }
  }
  .progress {
    position: fixed;
    width: 100%;
    z-index: 1;
    padding: 8px 16px 12px;
    background: map-get($colors, white);
    margin: -20px -16px 0;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.08);
    .count {
      margin-right: 6px;
      color: map-get($colors, blue);
      font-weight: 700;
      font-size: 24px;
      line-height: 33px;
      font-family: 'Noto Sans';
      &.finished {
        font-size: 18px;
        line-height: 25px;
        font-family: unset;
        min-width: 72px;
      }
    }
    &-status {
      font-size: 12px;
      margin-right: 8px;
      min-width: 76px;
      display: flex;
      align-items: baseline;
    }
    &-bar {
      position: relative;
      display: flex;
      .progress-line {
        position: absolute;
        &.step-done {
          @include pxRem(top, 4);
          left: -1px;
          width: 100%;
          height: 100%;
          &::before {
            background-image: url("#{$assetPath}images/progress_done.png");
          }
        }
        &::before {
          content: "";
          width: 100%;
          height: 100%;
          position: absolute;
          background-image: url("#{$assetPath}images/walkman.png");
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
    }
  }
  .page-content {
    padding: 69px 0 32px;
    &.more-spacing {
      padding-top: 74px;
    }
    .page-title {
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
      color: map-get($colors, blue);
      font-family: unset;
    }
    .page-count {
      margin-top: 24px;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
      color: map-get($colors, black);
    }
    .product-list {
      margin: 16px 0 54px;
      .product-item {
        & + .product-item {
          margin-top: 24px
        }
        &.is-plus {
          position: relative;
          margin-top: 75px;
          &::before {
            content: "";
            position: absolute;
            width: 39px;
            height: 75px;
            background-image: url("#{$assetPath}images/plus-big.png");
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            top: 0;
            left: 50%;
            transform: translate(-50%, -100%);
          }
        }
        &.has-result-text {
          text-align: center;
          .product-item-wrapper {
            text-align: initial;
          }
        }
        &-wrapper {
          border-radius: 4px;
          padding: 8px 16px 24px;
          background-color: map-get($colors, white);
          box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.08);
        }
        .break-spacing {
          margin: 18px 0;
        }
        img {
          display: block;
          margin: auto;
          height: auto;
        }
        .product-name {
          font-weight: 700;
          font-size: 18px;
          line-height: 27px;
          margin-top: 6px;
          color: map-get($colors, black);
        }
        .btn-product {
          margin-top: 16px
        }
      }
    }
    .banner-group {
      display: block;
    }
    .banner-image-wrapper {
      text-align: center;
      .banner-image {
        object-fit: contain;
        height: auto;
      }
    }
    .result-over-size {
      @include pxRem(margin-top, 32);
      .wrap-box {
        background-color: map-get($colors, white);
        margin: 0 4px;
        &:first-child {
          .dial-time {
            letter-spacing: -0.04em;
          }
          a {
            @include pxRem(margin-top, 6);
          }
        }
        .title-dial {
          @include pxRem(line-height, 27);
        }
        .dial-time {
          @include pxRem(margin-top, 8);
          @include pxRem(line-height, 32);
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0;
          color: map-get($colors, black);
        }
        a {
          @include pxRem(margin-top, 20);
          display: block;
        }
      }
      .request-mail {
        @include pxRem(margin, 32 0);
        .request-note {
          @include pxRem(font-size, 18);
          @include pxRem(line-height, 27);
          font-weight: 400;
          color: map-get($colors, black);
          span {
            font-family: cursive;
            margin-right: 3px;
          }
        }
      }
    }
    .break-spacing {
      @include pxRem(margin, 24 0);
      @include pxRem(font-size, 16);
      @include pxRem(line-height, 27);
      display: block;
      text-align: center;
      font-weight: 400;
      color: map-get($colors, black);
    }
    .btn {
      @include pxRem(border-radius, 6);
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      border-width: 2px;
      display: flex;
      img {
        width: 18.33px;
        height: 16.5px;
        margin-right: 7.75px;
      }
      &.btn-check {
        color: map-get($colors, blue);
        background-color: map-get($colors, white);
        margin-top: 24px;
        img {
          width: 16.7px;
          height: 17.11px;
        }
      }   
    }
  }
}
.product-pant {
  display: inline-block;
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  color: map-get($colors, orange);
  background: #FFECD9;
  padding: 4px 8px;
  border: 2px solid #ED7702;
  border-radius: 4px;
  margin-top: 8px;
}
.result-text {
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  color: #2D4198;
  margin-bottom: 16px;
  position: relative;
  display: inline-block;
  white-space: pre-line;
  opacity: 0;
  animation: visibility 0.4s forwards;
  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 13px;
    height: 24px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    bottom: 0;
  }
  &::before {
    background-image: url("#{$assetPath}images/before_hash.svg");
    left: -21px;
  }
  &::after {
    background-image: url("#{$assetPath}images/after_hash.svg");
    right: -21px;
  }
}
@keyframes visibility {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
