.react-webcam {
  position: fixed;
  height: 100%;
  width: 100%;
  &.is-flicked {
    opacity: 0;
  }
  .img-body {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    height: 80%;
    &.img-center {
      @include pxRem(width, 72);
      @include pxRem(height, 72);
      transform: translate(-50%, -50%);
      height: auto;
      z-index: 3;
    }
  }

  .tooltip-inner {
    @include pxRem(width, 140);
    @include pxRem(border-radius, 4);
    @include pxRem(-webkit-border-radius, 4);
    @include pxRem(font-size, 14);
    @include pxRem(line-height, 21);
    @include pxRem(padding, 6);
    position: absolute;
    top: 57%;
    right: 53%;
    text-decoration: none;
    font-weight: bold;
    background-color: #fff;
    z-index: 10;
    text-align: start;
    color: #333;
    .tooltip-angle {
      @include pxRem(top, -10);
      @include pxRem(border-left, solid 10 transparent);
      @include pxRem(border-bottom, solid 10 #fff);
      @include pxRem(border-right, solid 3 #fff);
      @include pxRem(border-top-right-radius, 3);
      position: absolute;
      line-height: 0;
      right: 8%;
    }
  }

  .hint-mark-camera {
    @include pxRem(height, 223);
    @include pxRem(width, 165);
    @include pxRem(bottom, 20);
    @include pxRem(right, 20);
    @include pxRem(border-radius, 6);
    @include pxRem(border, solid 3 map-get($colors, white));
    background-color: rgba(255, 255, 255, 0.85);
    -webkit-background-clip: padding-box; /* for Safari */
    background-clip: padding-box;
    position: absolute;
    justify-content: space-between;
    flex-direction: column;
    text-align: center;
    align-items: center;
    .hint-mark-title {
      @include pxRem(margin-top, 5);
      @include pxRem(font-size, 18);
      font-weight: bold;
    }
    .hint-mark-degrees {
      @include pxRem(right, 30);
      @include pxRem(font-size, 16);
      position: absolute;
      top: 50%;
      font-weight: bold;
    }
    .hint-mark-detail {
      @include pxRem(font-size, 14);
      @include pxRem(margin-bottom, 5);
      font-weight: bold;
      text-align: left;
    }
    .img-close-hint-mark {
      @include pxRem(width, 25);
      @include pxRem(height, 25);
      position: absolute;
      right: 0;
    }
    .img-hint-mark-pic-selfie {
      @include pxRem(width, 92);
      @include pxRem(height, 108);
    }
  }

  .btn-capture {
    @include pxRem(font-size, 18);
    @include pxRem(border-radius, 4);
    @include pxRem(height, 52);
    @include pxRem(width, 230);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    position: absolute;
    top: 68%;
    transform: translateX(-50%);
    left: 50%;
    border: 0;
    background-color: map-get($colors, light-blue);
    color: map-get($colors, white);
    z-index: 4;
    .ic-capture {
      @include pxRem(width, 20);
      @include pxRem(margin-right, 8);
      object-fit: contain;
    }
  }
  .camera-footer {
    @include pxRem(height, 80);
    @include pxRem(padding, 0 20);
    @include pxRem(margin-top, -2);
    line-height: 1.5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 4;
    background-color: map-get($colors, white);
  }
  .indicator {
    background-color: map-get($colors, red);
    position: absolute;
    z-index: 2;
  }

  #v-indicator {
    @include pxRem(width, 2);
    height: 100%;
    top: 0;
    bottom: 0;
  }

  #h-indicator {
    @include pxRem(height, 2);
    width: 100%;
    left: 0;
    right: 0;
  }

  #c-indicator {
    @include pxRem(width, 44);
    @include pxRem(height, 44);
    border-radius: 50%;
    background-color: #fff;
    position: absolute;
    transform: translate(-50%, -50%);
    transition: 0.3s ease-in-out;
    -webkit-transform: translate(-50%, -50%);
    -webkit-transition: background-color 0.3s ease-in-out;
    z-index: 2;
    &.is-valid {
      background: url(#{$assetPath}images/tick.png) center no-repeat;
    }
  }
  // Hide button play video
  video::-webkit-media-controls {
    display: none !important;
    -webkit-appearance: none;
  }
  /* This used to work for the parent element of button divs */
  /* But it does not work with newer browsers, the below doesn't hide the play button parent div */

  *::-webkit-media-controls-panel {
    display: none !important;
    -webkit-appearance: none;
  }

  /* Old shadow dom for play button */

  *::-webkit-media-controls-play-button {
    display: none !important;
    -webkit-appearance: none;
  }

  /* New shadow dom for play button */

  /* This one works! */

  *::-webkit-media-controls-start-playback-button {
    display: none !important;
    -webkit-appearance: none;
  }
}
.is-landscape {
  @include pxRem(font-size, 36);
  position: fixed;
  background: map-get($colors, white);
  width: 100vw;
  height: calc(100% - 60rem / 16);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  &.is-web {
    @include pxRem(font-size, 32);
    white-space: pre-line;
    padding: 0 20px;
  }
}

.is-shooting,
.is-front-shooting-done,
.is-front-shooting-guidance,
.is-selfie-side-shooting-done {
  @include pxRem(font-size, 200);
  @include pxRem(top, 0);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &.is-front-shooting-done,
  &.is-front-shooting-guidance,
  &.is-selfie-side-shooting-done {
    flex-direction: column;
    .front-shooting-img {
      @include pxRem(width, 202);
      @include pxRem(height, 202);
      @include pxRem(margin-bottom, 40);
    }
    .front-shooting-title {
      @include pxRem(font-size, 36);
      text-align: center;
      color: white;
      font-weight: 700;
    }
  }
  &.is-front-shooting-guidance {
    background-color: black;
    opacity: 1;
    .front-shooting-img {
      @include pxRem(width, 243);
      @include pxRem(height, 142);
    }
  }
  &.is-shooting {
    top: auto;
    font-weight: 800;
    color: map-get($colors, gray-1);
  }
  &.is-front-shooting-done {
    background-color: map-get($colors, transparent);
  }
}

.is-bg-shooting-done {
  height: 100%;
  opacity: 0.8;
  background-color: map-get($colors, blue);
}

.is-bg-selfie-shooting-done {
  height: 100%;
  opacity: 0.8;
  background-color: map-get($colors, orange);
}

.img-preview {
  height: 100%;
  position: fixed;
  background-size: cover;
  background-position: center;
  width: 100%;
}

.img-body {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  height: 80%;
  &.img-center {
    @include pxRem(width, 72);
    @include pxRem(height, 72);
    transform: translate(-50%, -50%);
    height: auto;
    z-index: 3;
  }
}

.flip-img-overwrite {
  transform: scaleX(-1) translate(50%, -50%) !important;
}
