.survey-page {
  margin: 0 -4px;
  &.web {
    .progress {
      margin-top: 37px;
    }
    .page-content {
      padding-top: 129px;
    }
  }
  .progress {
    position: fixed;
    width: 100%;
    z-index: 1;
    padding: 8px 16px 12px;
    background: map-get($colors, white);
    margin: -20px -16px 0;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.08);
    .count {
      margin-right: 6px;
      color: map-get($colors, blue);
      font-weight: 700;
      font-size: 24px;
      line-height: 33px;
      font-family: 'Noto Sans';
      &.finished {
        font-size: 18px;
        line-height: 25px;
        font-family: unset;
        min-width: 72px;
      }
    }
    &-status {
      font-size: 12px;
      margin-right: 8px;
      min-width: 76px;
      display: flex;
      align-items: baseline;
    }
    &-bar {
      position: relative;
      display: flex;
      .progress-line {
        position: absolute;
        &.step-0,
        &.step-1,
        &.step-2,
        &.step-3,
        &.step-done {
          @include pxRem(top, 4);
          left: -1px;
          width: 100%;
          height: 100%;
        }
        &.step-0 {
          top: 0;
        }
        &.step-1 {
          &::before {
            width: 55px;
            background-image: url("#{$assetPath}images/progress_1.png");
          }
        }
        &.step-2 {
          &::before {
            width: 136px;
            background-image: url("#{$assetPath}images/progress_2.png");
          }
        }
        &.step-3 {
          &::before {
            width: 202px;
            background-image: url("#{$assetPath}images/progress_3.png");
          }
        }
        &.step-done {
          left: 0;
          &::before {
            background-image: url("#{$assetPath}images/progress_done.png");
          }
        }
        &::before {
          content: "";
          width: 100%;
          height: 100%;
          position: absolute;
          background-image: url("#{$assetPath}images/walkman.png");
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
    }
  }
  .page-content {
    padding: 69px 0 32px;
    .page-survey-text {
      font-weight: 700;
      font-size: 20px;
      line-height: 27px;
      text-align: center;
      color: map-get($colors, blue);
    }
    .page-title {
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
      color: map-get($colors, blue);
      font-family: unset;
      margin-top: 16px;
    }
    .question-list {
      margin-top: 50px;
      .question-item {
        position: relative;
        &.active {
          .speech-bubble {
            display: flex;
          }
        }
        & + .question-item {
          &.active {
            &::before {
              height: 48px;
            }
          }
          &::before {
            content: '';
            display: block;
            height: 32px;
            background-image: url("#{$assetPath}images/before-line.svg");
            background-repeat: no-repeat;
            background-position: center
          }
        }
        &.load-more::after {
          content: '';
          display: block;
          height: 373px;
          background-image: url("#{$assetPath}images/load-more.svg");
          background-repeat: no-repeat;
          background-size: 100%;
        }
        &.active {
          .question-wrapper {
            background: map-get($colors, blue);
          }
          .question-title {
            color: map-get($colors, white);
            font-weight: 700;
          }
          .btn-group {
            .btn {
              background: map-get($colors, white);
              color: map-get($colors, blue);
            }
          }
        }
        .question-wrapper {
          background: map-get($colors, white);
          box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.08);
          border-radius: 6px;
          padding: 20px 16px;
          position: relative;
        }
        .speech-bubble {
          width: 124px;
          height: 47px;
          display: none;
          justify-content: center;
          align-items: center;
          font-weight: 700;
          font-size: 16px;
          padding-bottom: 7px;
          padding-right: 3px;
          color: map-get($colors, white);
          background-position: -2px -2px;
          background-image: url("#{$assetPath}images/step-start.png");
          background-repeat: no-repeat;
          background-position: center;
          background-size: 100%;
          position: absolute;
          left: 50%;
          top: 12px;
          transform: translate(-50%, -100%);
          &.next {
            width: 70px;
            height: 46px;
            background-image: url("#{$assetPath}images/step-next.png");
          }
          &.nearly {
            background-image: url("#{$assetPath}images/step-nearly.png");
          }
          &.last {
            width: 138px;
            height: 46px;
            background-image: url("#{$assetPath}images/step-last.png");
          }
        }
        .question-title {
          font-weight: 400;
          font-size: 18px;
          line-height: 27px;
          color: rgba(0, 0, 0, 1);
          font-family: unset;
        }
        .btn-group {
          display: flex;
          margin-top: 24px;
          &.column {
            flex-direction: column;
            .btn {
              height: auto;
              & + .btn {
                margin: 12px 0 0 0;
              }
            }
          }
          &.wrap {
            flex-wrap: wrap;
            justify-content: space-between;
            .btn {
              width: calc(50% - 7.5px);
              &:nth-child(3),
              &:nth-child(4) {
                margin-top: 12px;
              }
              & + .btn {
                margin-left: 0;
              }
            }
          }
          .btn {
            border-radius: 6px;
            font-weight: 700;
            font-size: 20px;
            line-height: 28px;
            height: auto;
            padding: 12px 16px;
            background: map-get($colors, gray-2);
            color: map-get($colors, white);
            border: 0;
            & + .btn {
              margin-left: 13px;
            }
            &.selected {
              background: map-get($colors, blue);
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding-left: 12px;
              padding-right: 12px;
              &::after {
                display: inline-block;
                content: '';
                width: 22px;
                height: 22px;
                background-image: url("#{$assetPath}images/selected.svg");
                background-repeat: no-repeat;
                background-position: center
              }
              .text-btn {
                flex: 1
              }
            }
          }
        }
        .load-first {
          img {
            // box-shadow: 2px 2px 4px rgb(0 0 0 / 8%);
            border-radius: 6px;
            width: 100%;
          }
        }
      }
    }
    .btn-submit {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 63px;
      position: relative;
      &.confirmed {
        margin-top: 98px;
        &::before {
          pointer-events: none;
          content: '';
          display: block;
          height: 46px;
          width: 235px;
          position: absolute;
          top: calc(-100% - 2px);
          left: 50%;
          transform: translateX(-50%);
          width: 235px;
          background-image: url("#{$assetPath}images/step-done.png");
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
        }
      }
      img {
        width: 18.33px;
        height: 18.33px;
        margin-right: 8px;
      }
    }
  }
}
