.selfie-front-shooting {
  @include pxRem(line-height, 24);
  position: relative;
  video {
    height: 90vh;
  }
  .flex-row {
    justify-content: space-between;
    flex-wrap: nowrap;
  }
  .instruction-camera {
    @include pxRem(padding, 0 20);
    position: absolute;
    bottom: 0px;
    background-color: map-get($colors, white);
  }
  .img-body {
    @include pxRem(top, 30);
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    height: 78%;
    &.img-center {
      top: 40%;
      transform: translate(-50%, -50%);
      height: auto;
    }
  }
  .btn-capture {
    @include pxRem(padding, 10 40);
    @include pxRem(font-size, 16);
    @include pxRem(border-radius, 4);
    position: absolute;
    top: 60%;
    transform: translateX(-50%);
    left: 50%;
    border: 0;
    background-color: map-get($colors, light-blue);
    color: map-get($colors, white);
  }
}
