.sound-settings {
  @include pxRem(margin-top, 60);
  .is-front-sound-settings {
    @include pxRem(font-size, 33);
    text-align: center;
    color: white;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .is-front-shooting-guidance {
    background-color: map-get($colors, black);
  }
  .preparation-sound {
    @include pxRem(height, 128);
    @include pxRem(width, 128);
    @include pxRem(margin-top, 35);
    background-color: map-get($colors, pure-black);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .volume-container {
    @include pxRem(margin-top, 55);
    position: relative;
    width: 80%;
    .uc-arrow-volume {
      @include pxRem(width, 22.5);
      @include pxRem(height, 20);
      @include pxRem(top, -27);
      display: flex;
      position: absolute;
      margin: auto;
      left: 0;
      right: 0;
    }
    .volume-track {
      @include pxRem(height, 12);
      @include pxRem(border-radius, 6);
      overflow: hidden;
      background-color: #000;
      .volume-track-bar {
        @include pxRem(border-radius, 6);
        height: 100%;
        background-color: map-get($colors, danger-light);
        -webkit-transition: none;
        transition: none;
      }
      .bg-turquoise {
        background-color: map-get($colors, turquoise);
      }
    }
    .volume-control {
      @include pxRem(height, 12);
      display: block;
      width: 100%;
      appearance: none;
      position: absolute;
      top: 0;
      left: 0;
      padding: 0;
      background-color: transparent;
      -webkit-appearance: none;
      background-repeat: no-repeat;
    }
    .volume-control::-webkit-slider-thumb {
      @include pxRem(height, 30);
      @include pxRem(width, 30);
      -webkit-appearance: none;
      opacity: 0;
    }
  }

  .btn-submit-audio {
    @include pxRem(margin-top, 40);
    background-color: map-get($colors, light-blue);
    width: 62%;
  }
  .uc-arrow {
    @include pxRem(height, 25);
    @include pxRem(width, 28);
  }
}
