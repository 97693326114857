.title-page {
  @include pxRem(margin-bottom, 16);
  color: map-get($colors, blue);
}
.under-line {
  position: relative;
  z-index: 1;
  &::after {
    @include pxRem(margin-top, -2);
    @include pxRem(height, 6);
    @include pxRem(border-radius, 8);
    position: absolute;
    content: "";
    display: block;
    width: 100%;
    background-color: map-get($colors, yellow);
    z-index: -1;
  }
}
.wrap-box {
  @include pxRem(box-shadow, 0 4 9.6 0.4 rgba(216, 216, 216, 0.57));
  @include pxRem(line-height, 30);
  @include pxRem(padding, 20);
}
.title-sm {
  @include pxRem(margin-top, 10);
  @include pxRem(margin-bottom, 8);
  @include pxRem(font-size, 18);
  @include pxRem(line-height, 25);
  color: map-get($colors, blue);
  font-weight: 400;
}
.title-desc {
  @include pxRem(font-size, 18);
  @include pxRem(line-height, 27);
}
.btn-camera {
  @include pxRem(margin-bottom, 32);
  line-height: 1;
  letter-spacing: 0.01em;
  font-weight: 400;
  &::before {
    @include pxRem(width, 22);
    @include pxRem(height, 22);
    @include pxRem(margin-right, 8);
    content: '';
    display: flex;
    background-image: url("#{$assetPath}images/camera-ic.svg");
    background-size: contain;
    background-repeat: no-repeat;
    span {
      border-bottom: solid 1px map-get($colors, blue);
    }
  }
}
