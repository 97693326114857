.body-size-get-method {
  @include pxRem(line-height, 24);
  > section {
    @include pxRem(margin-bottom, 30);
  }
  .btn {
    @include pxRem(margin-top, 8);
  }
  .custom-radio {
    @extend .custom-input;
    @include pxRem(margin-bottom, 14);
    @include pxRem(font-size, 18);
    position: relative;
    color: map-get($colors, black-1);
    background-color: map-get($colors, white);
    &::after {
      @include pxRem(right, 16);
      position: absolute;
      content: url("#{$assetPath}images/radio_btn.png");
    }
  }
  input[type="radio"] {
    display: none;
  }
  & input[type="radio"]:checked ~ .custom-radio {
    background-color: map-get($colors, blue);
    color: map-get($colors, white);
    border: 0;
    &::after {
      content: url("#{$assetPath}images/radio_btn_checked.png");
    }
  }
}
.camera-guide-wrapper {
  @include pxRem(margin, 32 auto 0);
  width: fit-content;
  .camera-label {
    @include pxRem(font-size, 18);
    @include pxRem(margin-left, 9);
    @include pxRem(margin-bottom, -1);
    line-height: 1;
    text-align: center;
    letter-spacing: 0.01em;
    color: map-get($colors, blue);
    display: inline-block;
    border-bottom: solid 1px;
    cursor: pointer;
  }
}
