.result-page {
  @include pxRem(line-height, 24);
  @include pxRem(margin, 0 -4);
  > section {
    @include pxRem(margin-bottom, 30);
    .title-page {
      @include pxRem(font-size, 30);
    }
    .title-sm {
      @include pxRem(margin-bottom, 21);
    }
  }
  .wrap-box {
    background-color: map-get($colors, white);
    @include pxRem(border-radius, 4);
    &.result-size {
      display: flex;
      justify-content: space-between;
    }
    .result-size-list,
    .result-size-text {
      @include pxRem(font-size, 18);
      @include pxRem(line-height, 32);
    }
    .result-size-list {
      color: map-get($colors, pure-black);
    }
    .result-size-text {
      font-weight: 700;
    }
    .product-title {
      @include pxRem(line-height, 27);
      @include pxRem(padding-bottom, 24);
      @include pxRem(font-size, 18);
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 2px dotted map-get($colors, border);
      &.size-free {
        // justify-content: left;
        .title-size {
          @include pxRem(max-width, 190);
          &-content {
            white-space: unset !important;
          }
        }
      }
      .title-size {
        @include pxRem(max-width, 128);
        @include pxRem(margin-left, 16);
        display: flex;
        .title-size-content {
          white-space: pre-wrap;
        }
      }
      .big-text {
        @include pxRem(font-size, 42);
        font-weight: 700;
      }
    }
    .label-orange {
      color: map-get($colors, orange) !important;
    }

    .label-yellow {
      color: map-get($colors, yellow) !important;
    }
    .content-image {
      @include pxRem(width, 104);
      @include pxRem(height, 104);
      object-fit: cover;
    }
    .btn {
      @include pxRem(border-radius, 6);
      @include pxRem(margin-top, 15);
    }
    .product-content {
      .content-wrapper {
        @include pxRem(min-width, 104);
      }
    }
  }
  .wrap-icon-img {
    @include pxRem(margin, 18 0);
    line-height: 0;
    text-align: center;
    .img-plus {
      @include pxRem(width, 36);
      @include pxRem(height, 36);
    }
    .arrow-down-big-img {
      @include pxRem(height, 22);
    }
  }
  .content-label {
    @include pxRem(margin-right, 30);
    @include pxRem(line-height, 27);
    @include pxRem(font-size, 18);
    .product-pant {
      @include pxRem(margin, 0 auto 8);
    }
  }
  .map-link {
    @include pxRem(font-size, 18);
    @include pxRem(line-height, 25);
    @include pxRem(border-radius, 6);
    @include pxRem(padding, 13 25);
    @include pxRem(margin-bottom, 32);
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 0.01em;
    color: map-get($colors, white);
    background: map-get($colors, orange);
    font-weight: 800;
    img {
      @include pxRem(width, 20);
      @include pxRem(height, 18);
      @include pxRem(margin-right, 8);
    }
  }
  .result-text {
    @include pxRem(font-size, 24);
    line-height: 1;
    margin-bottom: 0;
    color: map-get($colors, orange);
    &::before {
      background-image: url("#{$assetPath}images/before_hash_orange.png");
    }
    &::after {
      background-image: url("#{$assetPath}images/after_hash_orange.png");
    }
  }
  .result_point {
    @include pxRem(margin-top, 32);
    display: flex;
    .result-img {
      @include pxRem(margin-right, 16);
    }
  }
  .line-through {
    @include pxRem(margin, 32 0);
    background-color: rgba(45, 65, 152, 0.3);
    height: 1px;
  }
  .result-note {
    @include pxRem(font-size, 18);
    @include pxRem(line-height, 27);
    color: map-get($colors, black-1);
    text-align: justify;
  }
}
.result-over-size {
  @include pxRem(margin-top, 30);
  .result-apology {
    @include pxRem(margin-bottom, 30);
    @include pxRem(font-size, 18);
    text-align: justify;
  }
  .live-dial {
    @include pxRem(margin, 15 0);
    position: relative;
    .title-dial {
      @include pxRem(font-size, 22);
      color: map-get($colors, blue);
      font-weight: bold;
    }
    .dial-phone {
      @include pxRem(font-size, 32);
      @include pxRem(letter-spacing, -0.3);
      @include pxRem(margin-top, 6);
      width: 100%;
      font-family: "A-OTF Gothic MB101 Pro";
      font-weight: 700;
      color: map-get($colors, blue);
      text-align: start;
      .wrap-phone-img {
        @include pxRem(padding, 6);
        @include pxRem(margin-left, -6);
        img {
          @include pxRem(width, 25);
          @include pxRem(height, 25);
        }
      }
      .phone-num {
        position: relative;
        .phone-text {
          @include pxRem(font-size, 14);
          @include pxRem(left, 1);
          @include pxRem(margin-top, -10);
          position: absolute;
          width: 100%;
          font-family: $font-family-default !important;
          font-weight: normal;
        }
      }
    }
    .dial-time {
      @include pxRem(line-height, 24);
      @include pxRem(letter-spacing, -0.6);
      width: 100%;
      text-align: start;
    }
  }
  .request-mail {
    @include pxRem(margin, 20 4 0);
    @include pxRem(line-height, 27);
    @include pxRem(font-size, 18);
    text-align: justify;
    :nth-child(2) {
      @include pxRem(margin-bottom, 27);
    }
  }
  .mg-top-15 {
    @include pxRem(margin-top, 15);
  }
}
