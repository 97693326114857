.uploading-page {
  .uploading-area {
    @include pxRem(height, 330);
    @include pxRem(border-radius, 10);
    @include pxRem(box-shadow, 0 4 9.6 0.4 rgba(216, 216, 216, 0.57));
    &::after {
      @include pxRem(border-radius, 10);
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: map-get($colors, black);
      opacity: 0.4;
    }
    .upload-wrapper {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      .icon-loading {
        @include pxRem(width, 35);
        @include pxRem(height, 39);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .image-desc {
        @include pxRem(margin-top, 30);
        @include pxRem(font-size, 36);
        position: absolute;
        white-space: nowrap;
        left: -25%;
        transform: translateX(-25%);
        color: map-get($colors, white);
        &-progress {
          left: 10%;
        }
      }
    }
    .img-left, .img-right {
      position: relative;
    }
    .img-left {
      .img-body-front {
        height: 84%;
      }
      img {
        @include pxRem(border-top-left-radius, 10);
        @include pxRem(border-bottom-left-radius, 10);
      }
    }
    .img-right {
      .img-body-side {
        height: 84%;
      }
      img {
        @include pxRem(border-top-right-radius, 10);
        @include pxRem(border-bottom-right-radius, 10);
      }
    }
  }
  .wrap-box {
    .body-value {
      @include pxRem(font-size, 18);
    }
  }
}
