/**
  * Responsive area
  */
@mixin respond-above($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);
    @media (min-width: $breakpoint-value) {
      @content;
    }
  } @else {
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}
@mixin respond-below($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);
    @media (max-width: ($breakpoint-value)) {
      @content;
    }
  } @else {
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}
@mixin respond-below-vertical($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);
    @media (max-height: ($breakpoint-value)) {
      @content;
    }
  } @else {
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}
@mixin respond-between($lower, $upper) {
  @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {
    $lower-breakpoint: map-get($breakpoints, $lower);
    $upper-breakpoint: map-get($breakpoints, $upper);
    @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
      @content;
    }
  } @else {
    @if (map-has-key($breakpoints, $lower) == false) {
      @warn 'Your lower breakpoint was invalid: #{$lower}.';
    }
    @if (map-has-key($breakpoints, $upper) == false) {
      @warn 'Your upper breakpoint was invalid: #{$upper}.';
    }
  }
}
@mixin clearfix() {
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}
// Convert px to rem
@function strip-units($number) {
  @return $number / ($number * 0 + 1);
}
@function convertPxToRem($px) {
  @return 0rem + strip-units($px) / 16;
}
@mixin pxRem($property, $values) {
  // Create a couple of empty lists as output buffers
  $px-values: ();
  $rem-values: ();
  // Ensure $values is a list
  @if type-of($values) != "list" {
    $values: join((), $values);
  }
  // Loop through the $values list
  @each $value in $values {
    @if type-of($value) == number and (unitless($value) or unit($value) == px) {
      $px-values: join($px-values, 0px + ($value));
      $rem-values: join($rem-values, convertPxToRem($value));
    } @else {
      // No conversion:
      $px-values: join($px-values, $value);
      $rem-values: join($rem-values, $value);
    }
  }
  #{$property}: $px-values;
  // Don't add media query if it's not needed
  @if $px-values != $rem-values {
    // @media only all {
    #{$property}: $rem-values;
    // }
  }
}
// Flex box
@mixin display-flex() {
  display: flex; /* New, Spec - Opera 12.1, Firefox 20+ */
  display: -webkit-box; /* Old - iOS 6-, Safari 3.1-6 */
  display: -moz-box; /* Old - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox; /* Tweener - IE 10 */
  display: -webkit-flex; /* New - Chrome */
}
@mixin inline-flex {
  display: inline-flex;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
}
@mixin flex-direction($value) {
  @if $value == row {
    -webkit-flex-direction: $value;
    -ms-flex-direction: $value;
    flex-direction: $value;
  }
  @if $value == row-reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: horizontal;
    -moz-box-direction: reverse;
    -moz-box-orient: horizontal;
  } @else if $value == column {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -moz-box-direction: normal;
    -moz-box-orient: vertical;
  } @else if $value == column-reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: vertical;
    -moz-box-direction: reverse;
    -moz-box-orient: vertical;
  } @else {
    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
    -moz-box-direction: normal;
    -moz-box-orient: horizontal;
  }
}
@mixin flex-wrap($value: nowrap) {
  // No webkit/FF box fallback
  -webkit-flex-wrap: $value;
  @if $value == nowrap {
    -ms-flex-wrap: none;
  } @else {
    -ms-flex-wrap: $value;
  }
  flex-wrap: $value;
}
@mixin flex-flow(
  $values: (
    row nowrap,
  )
) {
  // No webkit/FF box fallback
  -webkit-flex-flow: $values;
  -ms-flex-flow: $values;
  flex-flow: $values;
}
@mixin justify-content($value: flex-start) {
  @if $value == flex-start {
    -webkit-box-pack: start;
    -moz-box-pack: start;
    -ms-flex-pack: start;
  } @else if $value == flex-end {
    -webkit-box-pack: end;
    -moz-box-pack: end;
    -ms-flex-pack: end;
  } @else if $value == space-between {
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
  } @else if $value == space-around {
    -ms-flex-pack: distribute;
  } @else {
    -webkit-box-pack: $value;
    -moz-box-pack: $value;
    -ms-flex-pack: $value;
  }
  -webkit-justify-content: $value;
  justify-content: $value;
}
@mixin align-items($value: stretch) {
  @if $value == flex-start {
    -webkit-box-align: start;
    -moz-box-align: start;
    -ms-flex-align: start;
  } @else if $value == flex-end {
    -webkit-box-align: end;
    -moz-box-align: end;
    -ms-flex-align: end;
  } @else {
    -webkit-box-align: $value;
    -moz-box-align: $value;
    -ms-flex-align: $value;
  }
  -webkit-align-items: $value;
  align-items: $value;
}
@mixin align-content($value: stretch) {
  // No webkit box fallback
  -webkit-align-content: $value;
  @if $value == flex-start {
    -ms-flex-line-pack: start;
  } @else if $value == flex-end {
    -ms-flex-line-pack: end;
  } @else {
    -ms-flex-line-pack: $value;
  }
  align-content: $value;
}
@mixin align-self($value: auto) {
  // No webkit box fallback
  -webkit-align-self: $value;
  @if $value == flex-start {
    -ms-flex-item-align: start;
  } @else if $value == flex-end {
    -ms-flex-item-align: end;
  } @else {
    -ms-flex-item-align: $value;
  }
  align-self: $value;
}
