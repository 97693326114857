.sound-settings {
  @include pxRem(margin-top, 50);
  .title-sound-setting {
    @include pxRem(font-size, 23);
  }
  .uc-arrow {
    @include pxRem(height, 18);
    @include pxRem(width, 22);
  }
}
