@charset "UTF-8";
/**
  * Responsive area
  */
.container::after {
  display: table;
  content: "";
  clear: both;
}

@font-face {
  font-family: "A-OTF Gothic MB101 Pro";
  font-weight: 700;
  src: url("https://uc-hc-bodygram-assets-prod.s3-ap-northeast-1.amazonaws.com/fonts/A-OTF-GothicMB101Pro-Bold.otf") format("opentype");
}
html,
body,
div,
span,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
address,
del,
em,
img,
small,
strong,
b,
u,
i,
hr,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
menu,
nav,
output,
ruby,
section,
summary,
button,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  word-wrap: break-word;
}

html {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

button {
  outline: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption,
th,
td {
  text-align: inherit;
  font-weight: inherit;
  vertical-align: middle;
}

q,
blockquote {
  quotes: none;
}

a img {
  border: none;
}

article,
aside,
details,
figure,
footer,
header,
menu,
nav,
section,
summary {
  display: block;
}

img {
  width: auto;
  height: 100%;
  max-width: 100%;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
}

* {
  outline: none;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
}

body {
  font-size: 16px;
  font-size: 1rem;
  background: #fff;
  color: #222;
  font-family: "Noto Sans", Arial, sans-serif;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000;
  font-weight: 700;
  padding: 0;
  margin: 0;
}

h1 {
  font-size: 28px;
  font-size: 1.75rem;
  font-family: "A-OTF Gothic MB101 Pro";
}

h2 {
  font-size: 24px;
  font-size: 1.5rem;
}

h3 {
  font-size: 22px;
  font-size: 1.375rem;
  font-family: "A-OTF Gothic MB101 Pro";
}

h4 {
  font-size: 20px;
  font-size: 1.25rem;
}

h5 {
  font-size: 18px;
  font-size: 1.125rem;
}

h6 {
  font-size: 16px;
  font-size: 1rem;
}

a {
  color: #222;
  text-decoration: none;
  cursor: pointer;
}

select {
  background-color: #fff;
}

p {
  line-height: 1.6;
}

html {
  scroll-behavior: smooth;
}

.defaul-flex-row {
  display: flex;
  /* New, Spec - Opera 12.1, Firefox 20+ */
  display: -webkit-box;
  /* Old - iOS 6-, Safari 3.1-6 */
  display: -moz-box;
  /* Old - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;
  /* Tweener - IE 10 */
  display: -webkit-flex;
  /* New - Chrome */
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -moz-box-direction: normal;
  -moz-box-orient: horizontal;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -moz-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -moz-box-align: stretch;
  -ms-flex-align: stretch;
  -webkit-align-items: stretch;
  align-items: stretch;
}

.defaul-flex-col {
  display: flex;
  /* New, Spec - Opera 12.1, Firefox 20+ */
  display: -webkit-box;
  /* Old - iOS 6-, Safari 3.1-6 */
  display: -moz-box;
  /* Old - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;
  /* Tweener - IE 10 */
  display: -webkit-flex;
  /* New - Chrome */
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -moz-box-direction: normal;
  -moz-box-orient: vertical;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -moz-box-align: stretch;
  -ms-flex-align: stretch;
  -webkit-align-items: stretch;
  align-items: stretch;
}

.flex, .flex-col, .flex-row {
  display: flex;
  /* New, Spec - Opera 12.1, Firefox 20+ */
  display: -webkit-box;
  /* Old - iOS 6-, Safari 3.1-6 */
  display: -moz-box;
  /* Old - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;
  /* Tweener - IE 10 */
  display: -webkit-flex;
  /* New - Chrome */
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -moz-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -moz-box-align: stretch;
  -ms-flex-align: stretch;
  -webkit-align-items: stretch;
  align-items: stretch;
  -webkit-align-content: space-between;
  -ms-flex-line-pack: space-between;
  align-content: space-between;
}
.flex-row {
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -moz-box-direction: normal;
  -moz-box-orient: horizontal;
}
.flex-col {
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -moz-box-direction: normal;
  -moz-box-orient: vertical;
}

#app {
  background-color: #f7f8ff;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
}
#app.app-except-header {
  min-height: calc(100vh - 5rem);
}
#app.app-except-background {
  background-color: #222 !important;
}

.container {
  max-width: 1230px;
  max-width: 76.875rem;
  margin: 0 auto;
}

.pd-app {
  padding: 20px;
  padding: 1.25rem;
}

.input-warning {
  border: 1px solid #f95656 !important;
}
.input-warning:focus {
  border-color: #f95656 !important;
}

.msg-error {
  bottom: -42px;
  bottom: -2.625rem;
  font-size: 14px;
  font-size: 0.875rem;
  color: #f95656;
  position: absolute;
  right: 0;
}

.header-page {
  padding: 10px 20px;
  padding: 0.625rem 1.25rem;
  max-height: 60px;
  max-height: 3.75rem;
  min-height: 60px;
  min-height: 3.75rem;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  z-index: 4;
}
.header-page.shadow {
  box-shadow: 0px 4px 9.6px 0.4px rgba(216, 216, 216, 0.57);
  box-shadow: 0rem 0.25rem 0.6rem 0.025rem rgba(216, 216, 216, 0.57);
}
.header-page .header-logo {
  height: 60px;
  height: 3.75rem;
}
.header-page .header-camera-logo {
  padding: 10px 0px;
  padding: 0.625rem 0rem;
}
.header-page .header-camera-logo .header-title {
  line-height: 26px;
  line-height: 1.625rem;
  color: #2d4198;
}
.header-page .btn-back {
  height: 30px;
  height: 1.875rem;
  width: 30px;
  width: 1.875rem;
  text-align: left;
}
.header-page .btn-back img {
  height: 18px;
  height: 1.125rem;
  width: 18px;
  width: 1.125rem;
}
.header-page .btn-right {
  height: 30px;
  height: 1.875rem;
  font-size: 18px;
  font-size: 1.125rem;
}

.header-page-camera {
  padding: 10px 20px;
  padding: 0.625rem 1.25rem;
  max-height: 60px;
  max-height: 3.75rem;
  min-height: 60px;
  min-height: 3.75rem;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  z-index: 4;
}
.header-page-camera .btn-back {
  height: 50px;
  height: 3.125rem;
  width: 50px;
  width: 3.125rem;
  border-radius: 25px;
  border-radius: 1.5625rem;
  text-align: left;
  background-color: white;
  text-align: center;
}
.header-page-camera .btn-back img {
  height: 30px;
  height: 1.875rem;
  width: 30px;
  width: 1.875rem;
}
.header-page-camera .btn-right {
  height: 50px;
  height: 3.125rem;
  font-size: 18px;
  font-size: 1.125rem;
  border-radius: 25px;
  border-radius: 1.5625rem;
  padding: 10px;
  padding: 0.625rem;
  background-color: white;
  text-align: center;
}

.lds-ring {
  display: inline-block;
  position: relative;
}
.lds-ring.blue div {
  border: 8px solid #5071ff;
  border: 0.5rem solid #5071ff;
  border-color: #5071ff #5071ff transparent transparent;
}
.lds-ring.orange div {
  border: 8px solid #ed7702;
  border: 0.5rem solid #ed7702;
  border-color: #ed7702 #ed7702 transparent transparent;
}
.lds-ring, .lds-ring div {
  height: 80px;
  height: 5rem;
  width: 80px;
  width: 5rem;
}
.lds-ring div {
  border: 8px solid #fff;
  border: 0.5rem solid #fff;
  box-sizing: border-box;
  display: block;
  position: absolute;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
  border-color: #fff;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
  border-color: #fff;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.btn {
  border-radius: 4px;
  border-radius: 0.25rem;
  font-size: 18px;
  font-size: 1.125rem;
  height: 52px;
  height: 3.25rem;
  font-weight: 700;
  font-family: "Noto Sans", Arial, sans-serif;
  color: #fff;
  border: 1px solid #2d4198;
  width: 100%;
  background-color: #2d4198;
  text-align: center;
}
.btn.disabled {
  opacity: 0.5 !important;
}
.btn:hover {
  opacity: 1;
}
.btn-link {
  line-height: 1;
  color: #2d4198;
  background-color: transparent;
}

.custom-input, .body-size-get-method .custom-radio {
  border-radius: 6px;
  border-radius: 0.375rem;
  font-size: 18px;
  font-size: 1.125rem;
  margin-top: 4px;
  margin-top: 0.25rem;
  padding: 0px 22px;
  padding: 0rem 1.375rem;
  height: 62px;
  height: 3.875rem;
  width: 100%;
  border: 2px solid #2d4198;
}
.custom-input:focus, .body-size-get-method .custom-radio:focus {
  border: 1px solid #2d4198;
}

.categories {
  border: 1px solid #cecece;
  display: flex;
  flex-direction: column;
}
.categories-item {
  height: 44px;
  height: 2.75rem;
  line-height: 44px;
  line-height: 2.75rem;
  display: flex;
  justify-content: center;
  position: relative;
}
.categories-item:not(:last-child) {
  border-bottom: 1px solid #cecece;
}
.categories-item::after {
  right: 16px;
  right: 1rem;
  top: 0;
  color: #2d4198;
  content: ">";
  display: block;
  position: absolute;
}

.error-box {
  position: fixed;
  z-index: 9;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}
.error-box .error-content {
  border-radius: 6px;
  border-radius: 0.375rem;
  padding: 20px;
  padding: 1.25rem;
  background-color: #fff;
  margin: auto;
  border: 2px solid #e1dfde;
  width: 80%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.error-box .msg-title {
  padding-bottom: 20px;
  padding-bottom: 1.25rem;
  border-bottom: 2px solid #eee;
  border-bottom: 0.125rem solid #eee;
}
.error-box .msg-content {
  padding: 30px 0px;
  padding: 1.875rem 0rem;
  white-space: pre-wrap;
}

.process-img {
  width: 100%;
}

.bodygram-splash {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bodygram-splash img {
  width: 134px;
  width: 8.375rem;
  object-fit: contain;
}

.custom-checkbox {
  font-size: 16px;
  font-size: 1rem;
  padding-left: 35px;
  padding-left: 2.1875rem;
  margin-bottom: 12px;
  margin-bottom: 0.75rem;
  line-height: 22px;
  line-height: 1.375rem;
  display: block;
  position: relative;
}
.custom-checkbox .custome-checkmark {
  height: 22px;
  height: 1.375rem;
  width: 22px;
  width: 1.375rem;
  border-radius: 4px;
  border-radius: 0.25rem;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  border: 1px solid #cecece;
}
.custom-checkbox .custome-checkmark:after {
  left: 7px;
  left: 0.4375rem;
  top: 3px;
  top: 0.1875rem;
  width: 4px;
  width: 0.25rem;
  height: 8px;
  height: 0.5rem;
  content: "";
  position: absolute;
  display: none;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.custom-checkbox input {
  position: absolute;
  cursor: pointer;
  opacity: 0;
  height: 0;
  width: 0;
}
.custom-checkbox input:checked ~ .custome-checkmark {
  background-color: #2d4198;
}
.custom-checkbox input:checked ~ .custome-checkmark:after {
  display: block;
}

.camera-error-box {
  position: fixed;
  z-index: 9;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 73px 24px 24px;
}
.camera-error-box .camera-error-wrapper {
  position: relative;
  background-color: #fff;
  padding: 24px 16px 26px;
}
.camera-error-box .camera-error-wrapper .close-modal-btn {
  display: inline-flex;
  background: none;
  border: 0;
  position: absolute;
  right: 12px;
  top: 12px;
  padding: 4px;
  cursor: pointer;
}
.camera-error-box .camera-error-wrapper .camera-title {
  padding: 24px 0;
  font-weight: 900;
  font-size: 24px;
  line-height: 35px;
  text-align: center;
  color: #2D4198;
}
.camera-error-box .camera-guide-list {
  margin-top: 5px;
}
.camera-error-box .camera-guide-list .guide-note-order {
  letter-spacing: 0.04em;
  font-weight: 700;
  font-size: 22px;
  line-height: 32px;
  margin-bottom: 16px;
}
.camera-error-box .guide-note-list {
  list-style-type: disc;
  margin: 16px 0 40px 27px;
}
.camera-error-box .guide-note-list .guide-note-item {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
}

.title-page {
  margin-bottom: 16px;
  margin-bottom: 1rem;
  color: #2d4198;
}

.under-line {
  position: relative;
  z-index: 1;
}
.under-line::after {
  margin-top: -2px;
  margin-top: -0.125rem;
  height: 6px;
  height: 0.375rem;
  border-radius: 8px;
  border-radius: 0.5rem;
  position: absolute;
  content: "";
  display: block;
  width: 100%;
  background-color: #f8b900;
  z-index: -1;
}

.wrap-box {
  box-shadow: 0px 4px 9.6px 0.4px rgba(216, 216, 216, 0.57);
  box-shadow: 0rem 0.25rem 0.6rem 0.025rem rgba(216, 216, 216, 0.57);
  line-height: 30px;
  line-height: 1.875rem;
  padding: 20px;
  padding: 1.25rem;
}

.title-sm {
  margin-top: 10px;
  margin-top: 0.625rem;
  margin-bottom: 8px;
  margin-bottom: 0.5rem;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 25px;
  line-height: 1.5625rem;
  color: #2d4198;
  font-weight: 400;
}

.title-desc {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 27px;
  line-height: 1.6875rem;
}

.btn-camera {
  margin-bottom: 32px;
  margin-bottom: 2rem;
  line-height: 1;
  letter-spacing: 0.01em;
  font-weight: 400;
}
.btn-camera::before {
  width: 22px;
  width: 1.375rem;
  height: 22px;
  height: 1.375rem;
  margin-right: 8px;
  margin-right: 0.5rem;
  content: "";
  display: flex;
  background-image: url("https://uc-hc-bodygram-assets-prod.s3-ap-northeast-1.amazonaws.com/images/camera-ic.svg");
  background-size: contain;
  background-repeat: no-repeat;
}
.btn-camera::before span {
  border-bottom: solid 1px #2d4198;
}

.Introduction {
  line-height: 26px;
  line-height: 1.625rem;
  margin-left: -4px;
  margin-left: -0.25rem;
  margin-right: -4px;
  margin-right: -0.25rem;
  margin-top: 1rem;
}
.Introduction .intro-text-header {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 27px;
  line-height: 1.6875rem;
  color: #333;
}
.Introduction .intro-content {
  justify-content: space-between;
}
.Introduction .flex, .Introduction .flex-row, .Introduction .flex-col {
  flex-wrap: inherit;
}
.Introduction h1 {
  margin-bottom: 16px;
  margin-bottom: 1rem;
  color: #2d4198;
}
.Introduction .intro {
  margin-top: 32px;
  margin-top: 2rem;
}
.Introduction .intro .intro-item .item-content {
  margin-top: 16px;
  margin-top: 1rem;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 27px;
  line-height: 1.6875rem;
  font-weight: 500;
  color: #333;
}
.Introduction .btn {
  margin-top: 40px;
  margin-top: 2.5rem;
}
.Introduction a {
  color: #fff;
}
.Introduction .policy-group {
  position: relative;
  margin-top: 40px;
  margin-top: 2.5rem;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 27px;
  line-height: 1.6875rem;
  color: #333;
}
.Introduction .policy-group .label-container {
  display: inline-block;
  position: relative;
  padding-left: 32px;
  margin-bottom: 18px;
  cursor: pointer;
  font-size: 22px;
  user-select: none;
}
.Introduction .policy-group .label-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.Introduction .policy-group .label-container input:checked ~ .checkmark {
  background-color: #2d4198;
  border: 0;
}
.Introduction .policy-group .label-container input:checked ~ .checkmark::after {
  display: block;
}
.Introduction .policy-group .label-container .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  border-radius: 2px;
  border: solid 1px #2d4198;
  background-color: #fff;
}
.Introduction .policy-group .label-container .checkmark::after {
  display: none;
  position: absolute;
  content: "";
  height: 8.98px;
  width: 12px;
  background-image: url("https://uc-hc-bodygram-assets-prod.s3-ap-northeast-1.amazonaws.com/images/white_tick.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  top: 7px;
  left: 5px;
}
.Introduction .policy-group .policy-link {
  border-bottom: solid 1px #2d4198;
  color: #2d4198;
  line-height: 27px;
  line-height: 1.6875rem;
}
.Introduction .intro-note {
  margin: 16px 0px 40px;
  margin: 1rem 0rem 2.5rem;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 27px;
  line-height: 1.6875rem;
  font-weight: 400;
  color: #333;
  font-family: "Noto Sans", Arial, sans-serif;
}

.bodygram-header {
  margin-bottom: 32px;
  margin-bottom: 2rem;
}
.bodygram-header-title {
  margin-bottom: 12px;
  margin-bottom: 0.75rem;
  font-size: 30px;
  font-size: 1.875rem;
  line-height: 43px;
  line-height: 2.6875rem;
  color: #2d4198;
  font-weight: 900;
}
.bodygram-header-desc {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 27px;
  line-height: 1.6875rem;
  color: #333;
}

.signup-phone-number > section {
  margin-bottom: 20px;
  margin-bottom: 1.25rem;
}

.signup-done .btn-submit {
  margin-top: 40px;
  margin-top: 2.5rem;
}

.body-size-input {
  line-height: 24px;
  line-height: 1.5rem;
}
.body-size-input .input-group ul li:not(:last-child) {
  margin-bottom: 16px;
  margin-bottom: 1rem;
}
.body-size-input .btn-submit {
  margin-top: 40px;
  margin-top: 2.5rem;
}
.body-size-input .size-input {
  position: relative;
}
.body-size-input .size-input .custom-input, .body-size-input .size-input .body-size-get-method .custom-radio, .body-size-get-method .body-size-input .size-input .custom-radio {
  max-height: 62px;
  max-height: 3.875rem;
  font-weight: 700;
  border: 1px solid #cecece !important;
}
.body-size-input .size-input input {
  padding-right: 40px;
  padding-right: 2.5rem;
}
.body-size-input .size-input input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-weight: 400;
}
.body-size-input .size-input input::-moz-placeholder {
  /* Firefox 19+ */
  font-weight: 400;
}
.body-size-input .size-input input:-ms-input-placeholder {
  /* IE 10+ */
  font-weight: 400;
}
.body-size-input .size-input input:-moz-placeholder {
  /* Firefox 18- */
  font-weight: 400;
}
.body-size-input .size-input::after {
  right: 18px;
  right: 1.125rem;
  position: absolute;
  top: 64%;
}
.body-size-input .size-input-age::after {
  font-size: 18px;
  font-size: 1.125rem;
  content: "歳";
  font-weight: 400;
}
.body-size-input .size-input-height::after {
  font-size: 18px;
  font-size: 1.125rem;
  content: "cm";
  font-weight: 400;
}
.body-size-input .size-input-weight::after {
  font-size: 18px;
  font-size: 1.125rem;
  content: "kg";
  font-weight: 400;
}
.body-size-input .size-input .ic-arrow-down {
  height: 10px;
  height: 0.625rem;
  width: 17px;
  width: 1.0625rem;
  right: 16px;
  right: 1rem;
  position: absolute;
  top: 80%;
}
.body-size-input .middle-line {
  text-align: center;
  padding-top: 8px;
}
.body-size-input .middle-line p {
  position: relative;
  display: inline-block;
  font-weight: bold;
}
.body-size-input .middle-line p::before {
  height: 1px;
  height: 0.0625rem;
  margin-top: -1px;
  margin-top: -0.0625rem;
  background: #cecece;
  position: absolute;
  content: "";
  top: 50%;
  right: -38vw;
  width: 36vw;
}
.body-size-input .middle-line p::after {
  height: 1px;
  height: 0.0625rem;
  margin-top: -1px;
  margin-top: -0.0625rem;
  background: #cecece;
  position: absolute;
  top: 50%;
  left: -38vw;
  content: "";
  width: 36vw;
}
.body-size-input .custom-checkbox,
.body-size-input .note-text {
  font-size: 18px;
  font-size: 1.125rem;
  margin: 0px -4px;
  margin: 0rem -0.25rem;
  line-height: 27px;
  line-height: 1.6875rem;
  color: #333;
}
.body-size-input .custom-checkbox a {
  color: #2d4198;
  text-decoration: underline;
}
.body-size-input .note-text {
  padding-left: 28px;
  padding-left: 1.75rem;
}

.body-size-get-method {
  line-height: 24px;
  line-height: 1.5rem;
}
.body-size-get-method > section {
  margin-bottom: 30px;
  margin-bottom: 1.875rem;
}
.body-size-get-method .btn {
  margin-top: 8px;
  margin-top: 0.5rem;
}
.body-size-get-method .custom-radio {
  margin-bottom: 14px;
  margin-bottom: 0.875rem;
  font-size: 18px;
  font-size: 1.125rem;
  position: relative;
  color: #333;
  background-color: #fff;
}
.body-size-get-method .custom-radio::after {
  right: 16px;
  right: 1rem;
  position: absolute;
  content: url("https://uc-hc-bodygram-assets-prod.s3-ap-northeast-1.amazonaws.com/images/radio_btn.png");
}
.body-size-get-method input[type=radio] {
  display: none;
}
.body-size-get-method input[type=radio]:checked ~ .custom-radio {
  background-color: #2d4198;
  color: #fff;
  border: 0;
}
.body-size-get-method input[type=radio]:checked ~ .custom-radio::after {
  content: url("https://uc-hc-bodygram-assets-prod.s3-ap-northeast-1.amazonaws.com/images/radio_btn_checked.png");
}

.camera-guide-wrapper {
  margin: 32px auto 0px;
  margin: 2rem auto 0rem;
  width: fit-content;
}
.camera-guide-wrapper .camera-label {
  font-size: 18px;
  font-size: 1.125rem;
  margin-left: 9px;
  margin-left: 0.5625rem;
  margin-bottom: -1px;
  margin-bottom: -0.0625rem;
  line-height: 1;
  text-align: center;
  letter-spacing: 0.01em;
  color: #2d4198;
  display: inline-block;
  border-bottom: solid 1px;
  cursor: pointer;
}

.waist-measurements {
  line-height: 27px;
  line-height: 1.6875rem;
}
.waist-measurements .btn-submit {
  margin-top: 20px;
  margin-top: 1.25rem;
}
.waist-measurements h1 {
  margin-bottom: 20px;
  margin-bottom: 1.25rem;
  color: #2d4198;
}
.waist-measurements section {
  margin-bottom: 20px;
  margin-bottom: 1.25rem;
}
.waist-measurements .waist-input {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 27px;
  line-height: 1.6875rem;
  color: #333;
  position: relative;
  font-weight: 700;
}
.waist-measurements .waist-input .custom-input, .waist-measurements .waist-input .body-size-get-method .custom-radio, .body-size-get-method .waist-measurements .waist-input .custom-radio {
  line-height: 24px;
  line-height: 1.5rem;
  max-height: 62px;
  max-height: 3.875rem;
  border: 1px solid #cecece !important;
}
.waist-measurements .waist-input input {
  padding-right: 46px;
  padding-right: 2.875rem;
  font-weight: 700;
}
.waist-measurements .waist-input input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-weight: 400;
}
.waist-measurements .waist-input input::-moz-placeholder {
  /* Firefox 19+ */
  font-weight: 400;
}
.waist-measurements .waist-input input:-moz-placeholder {
  /* Firefox 18- */
  font-weight: 400;
}
.waist-measurements .waist-input::after {
  right: 18px;
  right: 1.125rem;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 24px;
  line-height: 1.5rem;
  position: absolute;
  top: 68%;
  content: "cm";
  font-weight: 400;
}

.verification > section {
  margin-bottom: 20px;
  margin-bottom: 1.25rem;
}

.privacy-and-terms > section {
  margin-bottom: 20px;
  margin-bottom: 1.25rem;
}

.uploading-page .uploading-area {
  height: 330px;
  height: 20.625rem;
  border-radius: 10px;
  border-radius: 0.625rem;
  box-shadow: 0px 4px 9.6px 0.4px rgba(216, 216, 216, 0.57);
  box-shadow: 0rem 0.25rem 0.6rem 0.025rem rgba(216, 216, 216, 0.57);
}
.uploading-page .uploading-area::after {
  border-radius: 10px;
  border-radius: 0.625rem;
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #222;
  opacity: 0.4;
}
.uploading-page .uploading-area .upload-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
.uploading-page .uploading-area .upload-wrapper .icon-loading {
  width: 35px;
  width: 2.1875rem;
  height: 39px;
  height: 2.4375rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.uploading-page .uploading-area .upload-wrapper .image-desc {
  margin-top: 30px;
  margin-top: 1.875rem;
  font-size: 36px;
  font-size: 2.25rem;
  position: absolute;
  white-space: nowrap;
  left: -25%;
  transform: translateX(-25%);
  color: #fff;
}
.uploading-page .uploading-area .upload-wrapper .image-desc-progress {
  left: 10%;
}
.uploading-page .uploading-area .img-left, .uploading-page .uploading-area .img-right {
  position: relative;
}
.uploading-page .uploading-area .img-left .img-body-front {
  height: 84%;
}
.uploading-page .uploading-area .img-left img {
  border-top-left-radius: 10px;
  border-top-left-radius: 0.625rem;
  border-bottom-left-radius: 10px;
  border-bottom-left-radius: 0.625rem;
}
.uploading-page .uploading-area .img-right .img-body-side {
  height: 84%;
}
.uploading-page .uploading-area .img-right img {
  border-top-right-radius: 10px;
  border-top-right-radius: 0.625rem;
  border-bottom-right-radius: 10px;
  border-bottom-right-radius: 0.625rem;
}
.uploading-page .wrap-box .body-value {
  font-size: 18px;
  font-size: 1.125rem;
}

.preparation-image-list {
  list-style: none;
  counter-reset: li-counter;
}
.preparation-image-list .preparation-image-item {
  font-size: 22px;
  font-size: 1.375rem;
  margin-bottom: 40px;
  margin-bottom: 2.5rem;
  counter-increment: li-counter;
}
.preparation-image-list .preparation-image-item::before {
  width: 22px;
  width: 1.375rem;
  height: 22px;
  height: 1.375rem;
  margin: 2px;
  margin: 0.125rem;
  content: counter(li-counter);
  color: #222;
  border: 1px solid #222;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  position: relative;
}
.preparation-image-list .preparation-image-item .preparation-title {
  margin-bottom: 20px;
  margin-bottom: 1.25rem;
  display: inline-block;
}
.preparation-image-list .preparation-image-item .preparation-image {
  display: block;
  width: 100%;
}
.preparation-image-list .preparation-image-item .prearation-title-longskirt {
  margin-bottom: 20px;
  margin-bottom: 1.25rem;
  width: 100%;
}
.preparation-image-list .wrap-preparation-image {
  padding: 20px;
  padding: 1.25rem;
  display: flex;
  /* New, Spec - Opera 12.1, Firefox 20+ */
  display: -webkit-box;
  /* Old - iOS 6-, Safari 3.1-6 */
  display: -moz-box;
  /* Old - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;
  /* Tweener - IE 10 */
  display: -webkit-flex;
  /* New - Chrome */
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  background-color: #fff;
}
.preparation-image-list .wrap-preparation-image:not(:last-child) {
  margin-bottom: 20px;
  margin-bottom: 1.25rem;
}
.preparation-image-list .wrap-preparation-image img {
  margin-bottom: 20px;
  margin-bottom: 1.25rem;
}
.preparation-image-list .wrap-preparation-image ul {
  font-size: 16px;
  font-size: 1rem;
  padding-left: 16px;
  padding-left: 1rem;
  width: 100%;
}
.preparation-image-list .wrap-preparation-image ul li p::before {
  font-size: 16px;
  font-size: 1rem;
  margin-left: -16px;
  margin-left: -1rem;
  content: "・";
}
.preparation-image-list .wrap-preparation-image .uc-cloth {
  width: 269px;
  width: 16.8125rem;
  height: 125px;
  height: 7.8125rem;
}
.preparation-image-list .wrap-preparation-image .uc-standing {
  width: 156px;
  width: 9.75rem;
  height: 257px;
  height: 16.0625rem;
}
.preparation-image-list .wrap-preparation-image .uc-longskirt {
  width: 220px;
  width: 13.75rem;
  height: 121px;
  height: 7.5625rem;
}
.preparation-image-list .wrap-preparation-image .uc-cellphone {
  width: 238px;
  width: 14.875rem;
  height: 250px;
  height: 15.625rem;
}
.preparation-image-list .wrap-preparation-image .uc-desk {
  width: 208px;
  width: 13rem;
  height: 149px;
  height: 9.3125rem;
}
.preparation-image-list .wrap-preparation-image .uc-picturing {
  width: 218px;
  width: 13.625rem;
  height: 169px;
  height: 10.5625rem;
}
.preparation-image-list .wrap-preparation-image .uc-picturing-by-other {
  width: 253px;
  width: 15.8125rem;
  height: 193px;
  height: 12.0625rem;
}

.selfie-front-shooting {
  line-height: 24px;
  line-height: 1.5rem;
  position: relative;
}
.selfie-front-shooting video {
  height: 90vh;
}
.selfie-front-shooting .flex-row {
  justify-content: space-between;
  flex-wrap: nowrap;
}
.selfie-front-shooting .instruction-camera {
  padding: 0px 20px;
  padding: 0rem 1.25rem;
  position: absolute;
  bottom: 0px;
  background-color: #fff;
}
.selfie-front-shooting .img-body {
  top: 30px;
  top: 1.875rem;
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  height: 78%;
}
.selfie-front-shooting .img-body.img-center {
  top: 40%;
  transform: translate(-50%, -50%);
  height: auto;
}
.selfie-front-shooting .btn-capture {
  padding: 10px 40px;
  padding: 0.625rem 2.5rem;
  font-size: 16px;
  font-size: 1rem;
  border-radius: 4px;
  border-radius: 0.25rem;
  position: absolute;
  top: 60%;
  transform: translateX(-50%);
  left: 50%;
  border: 0;
  background-color: #5071ff;
  color: #fff;
}

.react-webcam {
  position: fixed;
  height: 100%;
  width: 100%;
  /* This used to work for the parent element of button divs */
  /* But it does not work with newer browsers, the below doesn't hide the play button parent div */
  /* Old shadow dom for play button */
  /* New shadow dom for play button */
  /* This one works! */
}
.react-webcam.is-flicked {
  opacity: 0;
}
.react-webcam .img-body {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  height: 80%;
}
.react-webcam .img-body.img-center {
  width: 72px;
  width: 4.5rem;
  height: 72px;
  height: 4.5rem;
  transform: translate(-50%, -50%);
  height: auto;
  z-index: 3;
}
.react-webcam .tooltip-inner {
  width: 140px;
  width: 8.75rem;
  border-radius: 4px;
  border-radius: 0.25rem;
  -webkit-border-radius: 4px;
  -webkit-border-radius: 0.25rem;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 21px;
  line-height: 1.3125rem;
  padding: 6px;
  padding: 0.375rem;
  position: absolute;
  top: 57%;
  right: 53%;
  text-decoration: none;
  font-weight: bold;
  background-color: #fff;
  z-index: 10;
  text-align: start;
  color: #333;
}
.react-webcam .tooltip-inner .tooltip-angle {
  top: -10px;
  top: -0.625rem;
  border-left: solid 10px transparent;
  border-left: solid 0.625rem transparent;
  border-bottom: solid 10px #fff;
  border-bottom: solid 0.625rem #fff;
  border-right: solid 3px #fff;
  border-right: solid 0.1875rem #fff;
  border-top-right-radius: 3px;
  border-top-right-radius: 0.1875rem;
  position: absolute;
  line-height: 0;
  right: 8%;
}
.react-webcam .hint-mark-camera {
  height: 223px;
  height: 13.9375rem;
  width: 165px;
  width: 10.3125rem;
  bottom: 20px;
  bottom: 1.25rem;
  right: 20px;
  right: 1.25rem;
  border-radius: 6px;
  border-radius: 0.375rem;
  border: solid 3px #fff;
  border: solid 0.1875rem #fff;
  background-color: rgba(255, 255, 255, 0.85);
  -webkit-background-clip: padding-box;
  /* for Safari */
  background-clip: padding-box;
  position: absolute;
  justify-content: space-between;
  flex-direction: column;
  text-align: center;
  align-items: center;
}
.react-webcam .hint-mark-camera .hint-mark-title {
  margin-top: 5px;
  margin-top: 0.3125rem;
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: bold;
}
.react-webcam .hint-mark-camera .hint-mark-degrees {
  right: 30px;
  right: 1.875rem;
  font-size: 16px;
  font-size: 1rem;
  position: absolute;
  top: 50%;
  font-weight: bold;
}
.react-webcam .hint-mark-camera .hint-mark-detail {
  font-size: 14px;
  font-size: 0.875rem;
  margin-bottom: 5px;
  margin-bottom: 0.3125rem;
  font-weight: bold;
  text-align: left;
}
.react-webcam .hint-mark-camera .img-close-hint-mark {
  width: 25px;
  width: 1.5625rem;
  height: 25px;
  height: 1.5625rem;
  position: absolute;
  right: 0;
}
.react-webcam .hint-mark-camera .img-hint-mark-pic-selfie {
  width: 92px;
  width: 5.75rem;
  height: 108px;
  height: 6.75rem;
}
.react-webcam .btn-capture {
  font-size: 18px;
  font-size: 1.125rem;
  border-radius: 4px;
  border-radius: 0.25rem;
  height: 52px;
  height: 3.25rem;
  width: 230px;
  width: 14.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  position: absolute;
  top: 68%;
  transform: translateX(-50%);
  left: 50%;
  border: 0;
  background-color: #5071ff;
  color: #fff;
  z-index: 4;
}
.react-webcam .btn-capture .ic-capture {
  width: 20px;
  width: 1.25rem;
  margin-right: 8px;
  margin-right: 0.5rem;
  object-fit: contain;
}
.react-webcam .camera-footer {
  height: 80px;
  height: 5rem;
  padding: 0px 20px;
  padding: 0rem 1.25rem;
  margin-top: -2px;
  margin-top: -0.125rem;
  line-height: 1.5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 4;
  background-color: #fff;
}
.react-webcam .indicator {
  background-color: #f00;
  position: absolute;
  z-index: 2;
}
.react-webcam #v-indicator {
  width: 2px;
  width: 0.125rem;
  height: 100%;
  top: 0;
  bottom: 0;
}
.react-webcam #h-indicator {
  height: 2px;
  height: 0.125rem;
  width: 100%;
  left: 0;
  right: 0;
}
.react-webcam #c-indicator {
  width: 44px;
  width: 2.75rem;
  height: 44px;
  height: 2.75rem;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  transform: translate(-50%, -50%);
  transition: 0.3s ease-in-out;
  -webkit-transform: translate(-50%, -50%);
  -webkit-transition: background-color 0.3s ease-in-out;
  z-index: 2;
}
.react-webcam #c-indicator.is-valid {
  background: url(https://uc-hc-bodygram-assets-prod.s3-ap-northeast-1.amazonaws.com/images/tick.png) center no-repeat;
}
.react-webcam video::-webkit-media-controls {
  display: none !important;
  -webkit-appearance: none;
}
.react-webcam *::-webkit-media-controls-panel {
  display: none !important;
  -webkit-appearance: none;
}
.react-webcam *::-webkit-media-controls-play-button {
  display: none !important;
  -webkit-appearance: none;
}
.react-webcam *::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none;
}

.is-landscape {
  font-size: 36px;
  font-size: 2.25rem;
  position: fixed;
  background: #fff;
  width: 100vw;
  height: calc(100% - 60rem / 16);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
}
.is-landscape.is-web {
  font-size: 32px;
  font-size: 2rem;
  white-space: pre-line;
  padding: 0 20px;
}

.is-shooting,
.is-front-shooting-done,
.is-front-shooting-guidance,
.is-selfie-side-shooting-done {
  font-size: 200px;
  font-size: 12.5rem;
  top: 0px;
  top: 0rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.is-shooting.is-front-shooting-done, .is-shooting.is-front-shooting-guidance, .is-shooting.is-selfie-side-shooting-done,
.is-front-shooting-done.is-front-shooting-done,
.is-front-shooting-done.is-front-shooting-guidance,
.is-front-shooting-done.is-selfie-side-shooting-done,
.is-front-shooting-guidance.is-front-shooting-done,
.is-front-shooting-guidance.is-front-shooting-guidance,
.is-front-shooting-guidance.is-selfie-side-shooting-done,
.is-selfie-side-shooting-done.is-front-shooting-done,
.is-selfie-side-shooting-done.is-front-shooting-guidance,
.is-selfie-side-shooting-done.is-selfie-side-shooting-done {
  flex-direction: column;
}
.is-shooting.is-front-shooting-done .front-shooting-img, .is-shooting.is-front-shooting-guidance .front-shooting-img, .is-shooting.is-selfie-side-shooting-done .front-shooting-img,
.is-front-shooting-done.is-front-shooting-done .front-shooting-img,
.is-front-shooting-done.is-front-shooting-guidance .front-shooting-img,
.is-front-shooting-done.is-selfie-side-shooting-done .front-shooting-img,
.is-front-shooting-guidance.is-front-shooting-done .front-shooting-img,
.is-front-shooting-guidance.is-front-shooting-guidance .front-shooting-img,
.is-front-shooting-guidance.is-selfie-side-shooting-done .front-shooting-img,
.is-selfie-side-shooting-done.is-front-shooting-done .front-shooting-img,
.is-selfie-side-shooting-done.is-front-shooting-guidance .front-shooting-img,
.is-selfie-side-shooting-done.is-selfie-side-shooting-done .front-shooting-img {
  width: 202px;
  width: 12.625rem;
  height: 202px;
  height: 12.625rem;
  margin-bottom: 40px;
  margin-bottom: 2.5rem;
}
.is-shooting.is-front-shooting-done .front-shooting-title, .is-shooting.is-front-shooting-guidance .front-shooting-title, .is-shooting.is-selfie-side-shooting-done .front-shooting-title,
.is-front-shooting-done.is-front-shooting-done .front-shooting-title,
.is-front-shooting-done.is-front-shooting-guidance .front-shooting-title,
.is-front-shooting-done.is-selfie-side-shooting-done .front-shooting-title,
.is-front-shooting-guidance.is-front-shooting-done .front-shooting-title,
.is-front-shooting-guidance.is-front-shooting-guidance .front-shooting-title,
.is-front-shooting-guidance.is-selfie-side-shooting-done .front-shooting-title,
.is-selfie-side-shooting-done.is-front-shooting-done .front-shooting-title,
.is-selfie-side-shooting-done.is-front-shooting-guidance .front-shooting-title,
.is-selfie-side-shooting-done.is-selfie-side-shooting-done .front-shooting-title {
  font-size: 36px;
  font-size: 2.25rem;
  text-align: center;
  color: white;
  font-weight: 700;
}
.is-shooting.is-front-shooting-guidance,
.is-front-shooting-done.is-front-shooting-guidance,
.is-front-shooting-guidance.is-front-shooting-guidance,
.is-selfie-side-shooting-done.is-front-shooting-guidance {
  background-color: black;
  opacity: 1;
}
.is-shooting.is-front-shooting-guidance .front-shooting-img,
.is-front-shooting-done.is-front-shooting-guidance .front-shooting-img,
.is-front-shooting-guidance.is-front-shooting-guidance .front-shooting-img,
.is-selfie-side-shooting-done.is-front-shooting-guidance .front-shooting-img {
  width: 243px;
  width: 15.1875rem;
  height: 142px;
  height: 8.875rem;
}
.is-shooting.is-shooting,
.is-front-shooting-done.is-shooting,
.is-front-shooting-guidance.is-shooting,
.is-selfie-side-shooting-done.is-shooting {
  top: auto;
  font-weight: 800;
  color: #e1dfde;
}
.is-bg-shooting-done {
  height: 100%;
  opacity: 0.8;
  background-color: #2d4198;
}

.is-bg-selfie-shooting-done {
  height: 100%;
  opacity: 0.8;
  background-color: #ed7702;
}

.img-preview {
  height: 100%;
  position: fixed;
  background-size: cover;
  background-position: center;
  width: 100%;
}

.img-body {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  height: 80%;
}
.img-body.img-center {
  width: 72px;
  width: 4.5rem;
  height: 72px;
  height: 4.5rem;
  transform: translate(-50%, -50%);
  height: auto;
  z-index: 3;
}

.flip-img-overwrite {
  transform: scaleX(-1) translate(50%, -50%) !important;
}

.sound-settings {
  margin-top: 60px;
  margin-top: 3.75rem;
}
.sound-settings .is-front-sound-settings {
  font-size: 33px;
  font-size: 2.0625rem;
  text-align: center;
  color: white;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.sound-settings .is-front-shooting-guidance {
  background-color: #222;
}
.sound-settings .preparation-sound {
  height: 128px;
  height: 8rem;
  width: 128px;
  width: 8rem;
  margin-top: 35px;
  margin-top: 2.1875rem;
  background-color: #000;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sound-settings .volume-container {
  margin-top: 55px;
  margin-top: 3.4375rem;
  position: relative;
  width: 80%;
}
.sound-settings .volume-container .uc-arrow-volume {
  width: 22.5px;
  width: 1.40625rem;
  height: 20px;
  height: 1.25rem;
  top: -27px;
  top: -1.6875rem;
  display: flex;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
}
.sound-settings .volume-container .volume-track {
  height: 12px;
  height: 0.75rem;
  border-radius: 6px;
  border-radius: 0.375rem;
  overflow: hidden;
  background-color: #000;
}
.sound-settings .volume-container .volume-track .volume-track-bar {
  border-radius: 6px;
  border-radius: 0.375rem;
  height: 100%;
  background-color: #fb5757;
  -webkit-transition: none;
  transition: none;
}
.sound-settings .volume-container .volume-track .bg-turquoise {
  background-color: #24cb8f;
}
.sound-settings .volume-container .volume-control {
  height: 12px;
  height: 0.75rem;
  display: block;
  width: 100%;
  appearance: none;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  background-repeat: no-repeat;
}
.sound-settings .volume-container .volume-control::-webkit-slider-thumb {
  height: 30px;
  height: 1.875rem;
  width: 30px;
  width: 1.875rem;
  -webkit-appearance: none;
  opacity: 0;
}
.sound-settings .btn-submit-audio {
  margin-top: 40px;
  margin-top: 2.5rem;
  background-color: #5071ff;
  width: 62%;
}
.sound-settings .uc-arrow {
  height: 25px;
  height: 1.5625rem;
  width: 28px;
  width: 1.75rem;
}

.result-page {
  line-height: 24px;
  line-height: 1.5rem;
  margin: 0px -4px;
  margin: 0rem -0.25rem;
}
.result-page > section {
  margin-bottom: 30px;
  margin-bottom: 1.875rem;
}
.result-page > section .title-page {
  font-size: 30px;
  font-size: 1.875rem;
}
.result-page > section .title-sm {
  margin-bottom: 21px;
  margin-bottom: 1.3125rem;
}
.result-page .wrap-box {
  background-color: #fff;
  border-radius: 4px;
  border-radius: 0.25rem;
}
.result-page .wrap-box.result-size {
  display: flex;
  justify-content: space-between;
}
.result-page .wrap-box .result-size-list,
.result-page .wrap-box .result-size-text {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 32px;
  line-height: 2rem;
}
.result-page .wrap-box .result-size-list {
  color: #000;
}
.result-page .wrap-box .result-size-text {
  font-weight: 700;
}
.result-page .wrap-box .product-title {
  line-height: 27px;
  line-height: 1.6875rem;
  padding-bottom: 24px;
  padding-bottom: 1.5rem;
  font-size: 18px;
  font-size: 1.125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 2px dotted #cecece;
}
.result-page .wrap-box .product-title.size-free .title-size {
  max-width: 190px;
  max-width: 11.875rem;
}
.result-page .wrap-box .product-title.size-free .title-size-content {
  white-space: unset !important;
}
.result-page .wrap-box .product-title .title-size {
  max-width: 128px;
  max-width: 8rem;
  margin-left: 16px;
  margin-left: 1rem;
  display: flex;
}
.result-page .wrap-box .product-title .title-size .title-size-content {
  white-space: pre-wrap;
}
.result-page .wrap-box .product-title .big-text {
  font-size: 42px;
  font-size: 2.625rem;
  font-weight: 700;
}
.result-page .wrap-box .label-orange {
  color: #ed7702 !important;
}
.result-page .wrap-box .label-yellow {
  color: #f8b900 !important;
}
.result-page .wrap-box .content-image {
  width: 104px;
  width: 6.5rem;
  height: 104px;
  height: 6.5rem;
  object-fit: cover;
}
.result-page .wrap-box .btn {
  border-radius: 6px;
  border-radius: 0.375rem;
  margin-top: 15px;
  margin-top: 0.9375rem;
}
.result-page .wrap-box .product-content .content-wrapper {
  min-width: 104px;
  min-width: 6.5rem;
}
.result-page .wrap-icon-img {
  margin: 18px 0px;
  margin: 1.125rem 0rem;
  line-height: 0;
  text-align: center;
}
.result-page .wrap-icon-img .img-plus {
  width: 36px;
  width: 2.25rem;
  height: 36px;
  height: 2.25rem;
}
.result-page .wrap-icon-img .arrow-down-big-img {
  height: 22px;
  height: 1.375rem;
}
.result-page .content-label {
  margin-right: 30px;
  margin-right: 1.875rem;
  line-height: 27px;
  line-height: 1.6875rem;
  font-size: 18px;
  font-size: 1.125rem;
}
.result-page .content-label .product-pant {
  margin: 0px auto 8px;
  margin: 0rem auto 0.5rem;
}
.result-page .map-link {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 25px;
  line-height: 1.5625rem;
  border-radius: 6px;
  border-radius: 0.375rem;
  padding: 13px 25px;
  padding: 0.8125rem 1.5625rem;
  margin-bottom: 32px;
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.01em;
  color: #fff;
  background: #ed7702;
  font-weight: 800;
}
.result-page .map-link img {
  width: 20px;
  width: 1.25rem;
  height: 18px;
  height: 1.125rem;
  margin-right: 8px;
  margin-right: 0.5rem;
}
.result-page .result-text {
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1;
  margin-bottom: 0;
  color: #ed7702;
}
.result-page .result-text::before {
  background-image: url("https://uc-hc-bodygram-assets-prod.s3-ap-northeast-1.amazonaws.com/images/before_hash_orange.png");
}
.result-page .result-text::after {
  background-image: url("https://uc-hc-bodygram-assets-prod.s3-ap-northeast-1.amazonaws.com/images/after_hash_orange.png");
}
.result-page .result_point {
  margin-top: 32px;
  margin-top: 2rem;
  display: flex;
}
.result-page .result_point .result-img {
  margin-right: 16px;
  margin-right: 1rem;
}
.result-page .line-through {
  margin: 32px 0px;
  margin: 2rem 0rem;
  background-color: rgba(45, 65, 152, 0.3);
  height: 1px;
}
.result-page .result-note {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 27px;
  line-height: 1.6875rem;
  color: #333;
  text-align: justify;
}

.result-over-size {
  margin-top: 30px;
  margin-top: 1.875rem;
}
.result-over-size .result-apology {
  margin-bottom: 30px;
  margin-bottom: 1.875rem;
  font-size: 18px;
  font-size: 1.125rem;
  text-align: justify;
}
.result-over-size .live-dial {
  margin: 15px 0px;
  margin: 0.9375rem 0rem;
  position: relative;
}
.result-over-size .live-dial .title-dial {
  font-size: 22px;
  font-size: 1.375rem;
  color: #2d4198;
  font-weight: bold;
}
.result-over-size .live-dial .dial-phone {
  font-size: 32px;
  font-size: 2rem;
  letter-spacing: -0.3px;
  letter-spacing: -0.01875rem;
  margin-top: 6px;
  margin-top: 0.375rem;
  width: 100%;
  font-family: "A-OTF Gothic MB101 Pro";
  font-weight: 700;
  color: #2d4198;
  text-align: start;
}
.result-over-size .live-dial .dial-phone .wrap-phone-img {
  padding: 6px;
  padding: 0.375rem;
  margin-left: -6px;
  margin-left: -0.375rem;
}
.result-over-size .live-dial .dial-phone .wrap-phone-img img {
  width: 25px;
  width: 1.5625rem;
  height: 25px;
  height: 1.5625rem;
}
.result-over-size .live-dial .dial-phone .phone-num {
  position: relative;
}
.result-over-size .live-dial .dial-phone .phone-num .phone-text {
  font-size: 14px;
  font-size: 0.875rem;
  left: 1px;
  left: 0.0625rem;
  margin-top: -10px;
  margin-top: -0.625rem;
  position: absolute;
  width: 100%;
  font-family: "Noto Sans", Arial, sans-serif !important;
  font-weight: normal;
}
.result-over-size .live-dial .dial-time {
  line-height: 24px;
  line-height: 1.5rem;
  letter-spacing: -0.6px;
  letter-spacing: -0.0375rem;
  width: 100%;
  text-align: start;
}
.result-over-size .request-mail {
  margin: 20px 4px 0px;
  margin: 1.25rem 0.25rem 0rem;
  line-height: 27px;
  line-height: 1.6875rem;
  font-size: 18px;
  font-size: 1.125rem;
  text-align: justify;
}
.result-over-size .request-mail :nth-child(2) {
  margin-bottom: 27px;
  margin-bottom: 1.6875rem;
}
.result-over-size .mg-top-15 {
  margin-top: 15px;
  margin-top: 0.9375rem;
}

.error404 h5 {
  margin: 30px 0px;
  margin: 1.875rem 0rem;
}

.survey-page {
  margin: 0 -4px;
}
.survey-page.web .progress {
  margin-top: 37px;
}
.survey-page.web .page-content {
  padding-top: 129px;
}
.survey-page .progress {
  position: fixed;
  width: 100%;
  z-index: 1;
  padding: 8px 16px 12px;
  background: #fff;
  margin: -20px -16px 0;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.08);
}
.survey-page .progress .count {
  margin-right: 6px;
  color: #2d4198;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  font-family: "Noto Sans";
}
.survey-page .progress .count.finished {
  font-size: 18px;
  line-height: 25px;
  font-family: unset;
  min-width: 72px;
}
.survey-page .progress-status {
  font-size: 12px;
  margin-right: 8px;
  min-width: 76px;
  display: flex;
  align-items: baseline;
}
.survey-page .progress-bar {
  position: relative;
  display: flex;
}
.survey-page .progress-bar .progress-line {
  position: absolute;
}
.survey-page .progress-bar .progress-line.step-0, .survey-page .progress-bar .progress-line.step-1, .survey-page .progress-bar .progress-line.step-2, .survey-page .progress-bar .progress-line.step-3, .survey-page .progress-bar .progress-line.step-done {
  top: 4px;
  top: 0.25rem;
  left: -1px;
  width: 100%;
  height: 100%;
}
.survey-page .progress-bar .progress-line.step-0 {
  top: 0;
}
.survey-page .progress-bar .progress-line.step-1::before {
  width: 55px;
  background-image: url("https://uc-hc-bodygram-assets-prod.s3-ap-northeast-1.amazonaws.com/images/progress_1.png");
}
.survey-page .progress-bar .progress-line.step-2::before {
  width: 136px;
  background-image: url("https://uc-hc-bodygram-assets-prod.s3-ap-northeast-1.amazonaws.com/images/progress_2.png");
}
.survey-page .progress-bar .progress-line.step-3::before {
  width: 202px;
  background-image: url("https://uc-hc-bodygram-assets-prod.s3-ap-northeast-1.amazonaws.com/images/progress_3.png");
}
.survey-page .progress-bar .progress-line.step-done {
  left: 0;
}
.survey-page .progress-bar .progress-line.step-done::before {
  background-image: url("https://uc-hc-bodygram-assets-prod.s3-ap-northeast-1.amazonaws.com/images/progress_done.png");
}
.survey-page .progress-bar .progress-line::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: url("https://uc-hc-bodygram-assets-prod.s3-ap-northeast-1.amazonaws.com/images/walkman.png");
  background-repeat: no-repeat;
  background-size: contain;
}
.survey-page .page-content {
  padding: 69px 0 32px;
}
.survey-page .page-content .page-survey-text {
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  color: #2d4198;
}
.survey-page .page-content .page-title {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #2d4198;
  font-family: unset;
  margin-top: 16px;
}
.survey-page .page-content .question-list {
  margin-top: 50px;
}
.survey-page .page-content .question-list .question-item {
  position: relative;
}
.survey-page .page-content .question-list .question-item.active .speech-bubble {
  display: flex;
}
.survey-page .page-content .question-list .question-item + .question-item.active::before {
  height: 48px;
}
.survey-page .page-content .question-list .question-item + .question-item::before {
  content: "";
  display: block;
  height: 32px;
  background-image: url("https://uc-hc-bodygram-assets-prod.s3-ap-northeast-1.amazonaws.com/images/before-line.svg");
  background-repeat: no-repeat;
  background-position: center;
}
.survey-page .page-content .question-list .question-item.load-more::after {
  content: "";
  display: block;
  height: 373px;
  background-image: url("https://uc-hc-bodygram-assets-prod.s3-ap-northeast-1.amazonaws.com/images/load-more.svg");
  background-repeat: no-repeat;
  background-size: 100%;
}
.survey-page .page-content .question-list .question-item.active .question-wrapper {
  background: #2d4198;
}
.survey-page .page-content .question-list .question-item.active .question-title {
  color: #fff;
  font-weight: 700;
}
.survey-page .page-content .question-list .question-item.active .btn-group .btn {
  background: #fff;
  color: #2d4198;
}
.survey-page .page-content .question-list .question-item .question-wrapper {
  background: #fff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  padding: 20px 16px;
  position: relative;
}
.survey-page .page-content .question-list .question-item .speech-bubble {
  width: 124px;
  height: 47px;
  display: none;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 16px;
  padding-bottom: 7px;
  padding-right: 3px;
  color: #fff;
  background-position: -2px -2px;
  background-image: url("https://uc-hc-bodygram-assets-prod.s3-ap-northeast-1.amazonaws.com/images/step-start.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  left: 50%;
  top: 12px;
  transform: translate(-50%, -100%);
}
.survey-page .page-content .question-list .question-item .speech-bubble.next {
  width: 70px;
  height: 46px;
  background-image: url("https://uc-hc-bodygram-assets-prod.s3-ap-northeast-1.amazonaws.com/images/step-next.png");
}
.survey-page .page-content .question-list .question-item .speech-bubble.nearly {
  background-image: url("https://uc-hc-bodygram-assets-prod.s3-ap-northeast-1.amazonaws.com/images/step-nearly.png");
}
.survey-page .page-content .question-list .question-item .speech-bubble.last {
  width: 138px;
  height: 46px;
  background-image: url("https://uc-hc-bodygram-assets-prod.s3-ap-northeast-1.amazonaws.com/images/step-last.png");
}
.survey-page .page-content .question-list .question-item .question-title {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: black;
  font-family: unset;
}
.survey-page .page-content .question-list .question-item .btn-group {
  display: flex;
  margin-top: 24px;
}
.survey-page .page-content .question-list .question-item .btn-group.column {
  flex-direction: column;
}
.survey-page .page-content .question-list .question-item .btn-group.column .btn {
  height: auto;
}
.survey-page .page-content .question-list .question-item .btn-group.column .btn + .btn {
  margin: 12px 0 0 0;
}
.survey-page .page-content .question-list .question-item .btn-group.wrap {
  flex-wrap: wrap;
  justify-content: space-between;
}
.survey-page .page-content .question-list .question-item .btn-group.wrap .btn {
  width: calc(50% - 7.5px);
}
.survey-page .page-content .question-list .question-item .btn-group.wrap .btn:nth-child(3), .survey-page .page-content .question-list .question-item .btn-group.wrap .btn:nth-child(4) {
  margin-top: 12px;
}
.survey-page .page-content .question-list .question-item .btn-group.wrap .btn + .btn {
  margin-left: 0;
}
.survey-page .page-content .question-list .question-item .btn-group .btn {
  border-radius: 6px;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  height: auto;
  padding: 12px 16px;
  background: #cdcdcd;
  color: #fff;
  border: 0;
}
.survey-page .page-content .question-list .question-item .btn-group .btn + .btn {
  margin-left: 13px;
}
.survey-page .page-content .question-list .question-item .btn-group .btn.selected {
  background: #2d4198;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
}
.survey-page .page-content .question-list .question-item .btn-group .btn.selected::after {
  display: inline-block;
  content: "";
  width: 22px;
  height: 22px;
  background-image: url("https://uc-hc-bodygram-assets-prod.s3-ap-northeast-1.amazonaws.com/images/selected.svg");
  background-repeat: no-repeat;
  background-position: center;
}
.survey-page .page-content .question-list .question-item .btn-group .btn.selected .text-btn {
  flex: 1;
}
.survey-page .page-content .question-list .question-item .load-first img {
  border-radius: 6px;
  width: 100%;
}
.survey-page .page-content .btn-submit {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 63px;
  position: relative;
}
.survey-page .page-content .btn-submit.confirmed {
  margin-top: 98px;
}
.survey-page .page-content .btn-submit.confirmed::before {
  pointer-events: none;
  content: "";
  display: block;
  height: 46px;
  width: 235px;
  position: absolute;
  top: calc(-100% - 2px);
  left: 50%;
  transform: translateX(-50%);
  width: 235px;
  background-image: url("https://uc-hc-bodygram-assets-prod.s3-ap-northeast-1.amazonaws.com/images/step-done.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.survey-page .page-content .btn-submit img {
  width: 18.33px;
  height: 18.33px;
  margin-right: 8px;
}

.survey-result-page {
  margin: 0 -4px;
}
.survey-result-page.web .progress {
  margin-top: 32px;
  padding-top: 13px;
}
.survey-result-page.web .page-content {
  padding-top: 129px;
}
.survey-result-page.web .page-content.more-spacing {
  padding-top: 134px;
}
.survey-result-page .progress {
  position: fixed;
  width: 100%;
  z-index: 1;
  padding: 8px 16px 12px;
  background: #fff;
  margin: -20px -16px 0;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.08);
}
.survey-result-page .progress .count {
  margin-right: 6px;
  color: #2d4198;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  font-family: "Noto Sans";
}
.survey-result-page .progress .count.finished {
  font-size: 18px;
  line-height: 25px;
  font-family: unset;
  min-width: 72px;
}
.survey-result-page .progress-status {
  font-size: 12px;
  margin-right: 8px;
  min-width: 76px;
  display: flex;
  align-items: baseline;
}
.survey-result-page .progress-bar {
  position: relative;
  display: flex;
}
.survey-result-page .progress-bar .progress-line {
  position: absolute;
}
.survey-result-page .progress-bar .progress-line.step-done {
  top: 4px;
  top: 0.25rem;
  left: -1px;
  width: 100%;
  height: 100%;
}
.survey-result-page .progress-bar .progress-line.step-done::before {
  background-image: url("https://uc-hc-bodygram-assets-prod.s3-ap-northeast-1.amazonaws.com/images/progress_done.png");
}
.survey-result-page .progress-bar .progress-line::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: url("https://uc-hc-bodygram-assets-prod.s3-ap-northeast-1.amazonaws.com/images/walkman.png");
  background-repeat: no-repeat;
  background-size: contain;
}
.survey-result-page .page-content {
  padding: 69px 0 32px;
}
.survey-result-page .page-content.more-spacing {
  padding-top: 74px;
}
.survey-result-page .page-content .page-title {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #2d4198;
  font-family: unset;
}
.survey-result-page .page-content .page-count {
  margin-top: 24px;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #222;
}
.survey-result-page .page-content .product-list {
  margin: 16px 0 54px;
}
.survey-result-page .page-content .product-list .product-item + .product-item {
  margin-top: 24px;
}
.survey-result-page .page-content .product-list .product-item.is-plus {
  position: relative;
  margin-top: 75px;
}
.survey-result-page .page-content .product-list .product-item.is-plus::before {
  content: "";
  position: absolute;
  width: 39px;
  height: 75px;
  background-image: url("https://uc-hc-bodygram-assets-prod.s3-ap-northeast-1.amazonaws.com/images/plus-big.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  top: 0;
  left: 50%;
  transform: translate(-50%, -100%);
}
.survey-result-page .page-content .product-list .product-item.has-result-text {
  text-align: center;
}
.survey-result-page .page-content .product-list .product-item.has-result-text .product-item-wrapper {
  text-align: initial;
}
.survey-result-page .page-content .product-list .product-item-wrapper {
  border-radius: 4px;
  padding: 8px 16px 24px;
  background-color: #fff;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.08);
}
.survey-result-page .page-content .product-list .product-item .break-spacing {
  margin: 18px 0;
}
.survey-result-page .page-content .product-list .product-item img {
  display: block;
  margin: auto;
  height: auto;
}
.survey-result-page .page-content .product-list .product-item .product-name {
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  margin-top: 6px;
  color: #222;
}
.survey-result-page .page-content .product-list .product-item .btn-product {
  margin-top: 16px;
}
.survey-result-page .page-content .banner-group {
  display: block;
}
.survey-result-page .page-content .banner-image-wrapper {
  text-align: center;
}
.survey-result-page .page-content .banner-image-wrapper .banner-image {
  object-fit: contain;
  height: auto;
}
.survey-result-page .page-content .result-over-size {
  margin-top: 32px;
  margin-top: 2rem;
}
.survey-result-page .page-content .result-over-size .wrap-box {
  background-color: #fff;
  margin: 0 4px;
}
.survey-result-page .page-content .result-over-size .wrap-box:first-child .dial-time {
  letter-spacing: -0.04em;
}
.survey-result-page .page-content .result-over-size .wrap-box:first-child a {
  margin-top: 6px;
  margin-top: 0.375rem;
}
.survey-result-page .page-content .result-over-size .wrap-box .title-dial {
  line-height: 27px;
  line-height: 1.6875rem;
}
.survey-result-page .page-content .result-over-size .wrap-box .dial-time {
  margin-top: 8px;
  margin-top: 0.5rem;
  line-height: 32px;
  line-height: 2rem;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;
  color: #222;
}
.survey-result-page .page-content .result-over-size .wrap-box a {
  margin-top: 20px;
  margin-top: 1.25rem;
  display: block;
}
.survey-result-page .page-content .result-over-size .request-mail {
  margin: 32px 0px;
  margin: 2rem 0rem;
}
.survey-result-page .page-content .result-over-size .request-mail .request-note {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 27px;
  line-height: 1.6875rem;
  font-weight: 400;
  color: #222;
}
.survey-result-page .page-content .result-over-size .request-mail .request-note span {
  font-family: cursive;
  margin-right: 3px;
}
.survey-result-page .page-content .break-spacing {
  margin: 24px 0px;
  margin: 1.5rem 0rem;
  font-size: 16px;
  font-size: 1rem;
  line-height: 27px;
  line-height: 1.6875rem;
  display: block;
  text-align: center;
  font-weight: 400;
  color: #222;
}
.survey-result-page .page-content .btn {
  border-radius: 6px;
  border-radius: 0.375rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-width: 2px;
  display: flex;
}
.survey-result-page .page-content .btn img {
  width: 18.33px;
  height: 16.5px;
  margin-right: 7.75px;
}
.survey-result-page .page-content .btn.btn-check {
  color: #2d4198;
  background-color: #fff;
  margin-top: 24px;
}
.survey-result-page .page-content .btn.btn-check img {
  width: 16.7px;
  height: 17.11px;
}

.product-pant {
  display: inline-block;
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  color: #ed7702;
  background: #FFECD9;
  padding: 4px 8px;
  border: 2px solid #ED7702;
  border-radius: 4px;
  margin-top: 8px;
}

.result-text {
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  color: #2D4198;
  margin-bottom: 16px;
  position: relative;
  display: inline-block;
  white-space: pre-line;
  opacity: 0;
  animation: visibility 0.4s forwards;
}
.result-text::before, .result-text::after {
  content: "";
  position: absolute;
  width: 13px;
  height: 24px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  bottom: 0;
}
.result-text::before {
  background-image: url("https://uc-hc-bodygram-assets-prod.s3-ap-northeast-1.amazonaws.com/images/before_hash.svg");
  left: -21px;
}
.result-text::after {
  background-image: url("https://uc-hc-bodygram-assets-prod.s3-ap-northeast-1.amazonaws.com/images/after_hash.svg");
  right: -21px;
}

@keyframes visibility {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.pointer-event-none {
  pointer-events: none;
}

.p-2 {
  padding: 8px;
  padding: 0.5rem;
}

.px-2 {
  padding-left: 8px;
  padding-left: 0.5rem;
  padding-right: 8px;
  padding-right: 0.5rem;
}

.px-3 {
  padding-left: 16px;
  padding-left: 1rem;
  padding-right: 16px;
  padding-right: 1rem;
}

.py-2 {
  padding-top: 8px;
  padding-top: 0.5rem;
  padding-bottom: 8px;
  padding-bottom: 0.5rem;
}

.py-3 {
  padding-top: 16px;
  padding-top: 1rem;
  padding-bottom: 16px;
  padding-bottom: 1rem;
}

.py-4 {
  padding-top: 24px;
  padding-top: 1.5rem;
  padding-bottom: 24px;
  padding-bottom: 1.5rem;
}

.pt-2 {
  padding-top: 10px;
  padding-top: 0.625rem;
}

.pb-3 {
  padding-bottom: 16px;
  padding-bottom: 1rem;
}

.pt-4 {
  padding-top: 20px;
  padding-top: 1.25rem;
}

.pb-4 {
  padding-bottom: 20px;
  padding-bottom: 1.25rem;
}

.mt-5 {
  margin-top: 32px;
  margin-top: 2rem;
}

.mt-4 {
  margin-top: 24px;
  margin-top: 1.5rem;
}

.mt-3 {
  margin-top: 16px;
  margin-top: 1rem;
}

.mt-2 {
  margin-top: 8px;
  margin-top: 0.5rem;
}

.mt-1 {
  margin-top: 4px;
  margin-top: 0.25rem;
}

.mb-4 {
  margin-bottom: 20px !important;
  margin-bottom: 1.25rem !important;
}

.mb-3 {
  margin-bottom: 16px;
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: 8px;
  margin-bottom: 0.5rem;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.ml-3 {
  margin-left: 16px;
  margin-left: 1rem;
}

.ml-2 {
  margin-left: 8px;
  margin-left: 0.5rem;
}

.d-none {
  display: none;
}

.d-none-imp {
  display: none !important;
}

.d-block {
  display: block;
}

.d-flex, .flex-centered {
  display: flex;
  /* New, Spec - Opera 12.1, Firefox 20+ */
  display: -webkit-box;
  /* Old - iOS 6-, Safari 3.1-6 */
  display: -moz-box;
  /* Old - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;
  /* Tweener - IE 10 */
  display: -webkit-flex;
  /* New - Chrome */
}

.d-inline-block {
  display: inline-block;
}

.flex-no-wrap {
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: none;
  flex-wrap: nowrap;
}

.align-items-center, .flex-centered {
  align-items: center;
}

.justify-content-center, .flex-centered {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-around {
  justify-content: space-around;
}

.justify-content-evenly {
  justify-content: space-evenly;
}

.pre {
  white-space: pre !important;
}

.pre-wrap {
  white-space: pre-wrap;
}

.txt-center {
  text-align: center;
}

.blue {
  color: #2d4198;
}

.yellow {
  color: #f8b900 !important;
}

.orange {
  color: #ed7702 !important;
}

.txt-danger {
  color: #f95656 !important;
}

.bg-organge-light {
  background-color: #ffecd9;
}

.bg-transparent {
  background-color: transparent !important;
}

.border-1 {
  border: 1px solid #cecece;
}

.border-0 {
  border: 0 !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.middle {
  vertical-align: middle;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-bold {
  font-weight: bold !important;
}

.fs-18 {
  font-size: 18px !important;
  font-size: 1.125rem !important;
}

.hidden {
  visibility: hidden;
}

.h-0 {
  height: 0;
}

.h-auto {
  height: auto;
}

.max-height-100 {
  max-height: 100%;
}

.mx-initial {
  max-width: initial;
}

.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

.link {
  color: #2d4198 !important;
  text-decoration: underline !important;
}

.is-hidden {
  display: none;
}

.is-relative {
  position: relative;
}

.is-absolute {
  position: absolute;
}

.is-fixed {
  position: fixed;
}

.position-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.object-fit-contain {
  object-fit: contain;
}

.object-fit-cover {
  object-fit: cover;
}

.bg-blue {
  background-color: #2d4198;
}

.line-height-0 {
  line-height: 0 !important;
}

.flip-img {
  transform: scaleX(-1) !important;
}

.overflow-hidden {
  overflow: hidden;
}

@media (max-width: 480px) {
  html {
    font-size: 16px;
  }
}
@media (max-height: 674px) {
  .sound-settings .title-sound-setting {
    font-size: 32px;
    font-size: 2rem;
  }
}
@media (max-height: 620px) {
  .react-webcam .hint-mark-camera {
    height: 190px;
    height: 11.875rem;
    bottom: 15px;
    bottom: 0.9375rem;
    right: 15px;
    right: 0.9375rem;
  }

  .sound-settings .title-sound-setting {
    font-size: 28px;
    font-size: 1.75rem;
  }
  .sound-settings .uc-arrow {
    height: 22px;
    height: 1.375rem;
    width: 25px;
    width: 1.5625rem;
  }
}
@media (max-height: 574px) {
  .sound-settings {
    margin-top: 50px;
    margin-top: 3.125rem;
  }
  .sound-settings .title-sound-setting {
    font-size: 23px;
    font-size: 1.4375rem;
  }
  .sound-settings .uc-arrow {
    height: 18px;
    height: 1.125rem;
    width: 22px;
    width: 1.375rem;
  }
}
@media (max-width: 374px) {
  html {
    font-size: 15px;
  }

  .react-webcam .hint-mark-camera {
    width: 150px;
    width: 9.375rem;
    bottom: 15px;
    bottom: 0.9375rem;
    right: 15px;
    right: 0.9375rem;
  }
}
@media (max-width: 354px) {
  html {
    font-size: 14px;
  }
}
@media (max-width: 329px) {
  html {
    font-size: 13px;
  }
}
@media (max-width: 320px) {
  html {
    font-size: 13px;
  }
}