.waist-measurements {
  @include pxRem(line-height, 27);
  // @include pxRem(margin-top, 20);
  .btn-submit {
    @include pxRem(margin-top, 20);
  }
  h1 {
    @include pxRem(margin-bottom, 20);
    color: map-get($colors, blue);
  }
  section {
    @include pxRem(margin-bottom, 20);
  }
  .waist-input {
    @include pxRem(font-size, 18);
    @include pxRem(line-height, 27);
    color: map-get($colors, black-1);
    position: relative;
    font-weight: 700;
    .custom-input {
      @include pxRem(line-height, 24);
      @include pxRem(max-height, 62);
      border: 1px solid map-get($colors, border) !important;
    }
    input {
      @include pxRem(padding-right, 46);
      font-weight: 700;
      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        font-weight: 400;
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        font-weight: 400;
      }
      &:-moz-placeholder {
        /* Firefox 18- */
        font-weight: 400;
      }
    }
    &::after {
      @include pxRem(right, 18);
      @include pxRem(font-size, 18);
      @include pxRem(line-height, 24);
      position: absolute;
      top: 68%;
      content: "cm";
      font-weight: 400;
    }
  }
}
