#app {
  background-color: map-get($colors, bg-app);
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  &.app-except-header {
    min-height: calc(100vh - 5rem);
  }
  &.app-except-background {
    background-color: map-get($colors, black) !important;
  }
}
.container {
  @include pxRem(max-width, $container-width);
  @extend %clr;
  margin: 0 auto;
}
.pd-app {
  @include pxRem(padding, 20);
}
