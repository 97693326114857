* {
  outline: none;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
}
body {
  @include pxRem(font-size, map-get($font-size, md));
  background: map-get($colors, white);
  color: map-get($colors, black);
  font-family: $font-family-default;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000;
  font-weight: 700;
  padding: 0;
  margin: 0;
}
h1 {
  @include pxRem(font-size, 28);
  font-family: "A-OTF Gothic MB101 Pro";
}
h2 {
  @include pxRem(font-size, 24);
}
h3 {
  @include pxRem(font-size, 22);
  font-family: "A-OTF Gothic MB101 Pro";
}
h4 {
  @include pxRem(font-size, 20);
}
h5 {
  @include pxRem(font-size, 18);
}
h6 {
  @include pxRem(font-size, 16);
}
a {
  color: map-get($colors, black);
  text-decoration: none;
  cursor: pointer;
}
select {
  background-color: map-get($colors, white);
}
p {
  line-height: 1.6;
}
html {
  scroll-behavior: smooth;
}
