// Config assetPath
$assetPath: $imgUrl;
// Fonts
$font-family-default: "Noto Sans", Arial, sans-serif;
$container-width: 1230px;
$colors: (
  red: #f00,
  blue: #2d4198,
  yellow: #f8b900,
  orange: #ed7702,
  orange-light: #ffecd9,
  danger: #f95656,
  danger-light: #fb5757,
  border: #cecece,
  black: #222,
  black-1: #333,
  white: #fff,
  gray: #eee,
  gray-1: #e1dfde,
  gray-2: #cdcdcd,
  bg-app: #f7f8ff,
  light-blue: #5071ff,
  turquoise: #24cb8f,
  pure-black: #000,
);
// Breakpoints
$breakpoints: (
  x-small: 320px,
  sc_329: 329px,
  sc_354: 354px,
  sc_374: 374px,
  sc_h_574: 574px,
  sc_h_620: 620px,
  sc_h_674: 674px,
  small: 480px,
  medium: 768px,
  wide: 992px,
  large: 1024px,
  largest: 1280px,
);
// Font sizes
$font-size: (
  xs: 10,
  sm: 12,
  md: 16,
  lg: 18,
  xl: 20,
  xxl: 26,
);
