.preparation-image-list {
  list-style: none;
  counter-reset: li-counter;
  .preparation-image-item {
    @include pxRem(font-size, 22);
    @include pxRem(margin-bottom, 40);
    counter-increment: li-counter;
    &::before {
      @include pxRem(width, 22);
      @include pxRem(height, 22);
      @include pxRem(margin, 2);
      content: counter(li-counter);
      color: map-get($colors, black);
      border: 1px solid map-get($colors, black);
      border-radius: 50%;
      display: inline-block;
      text-align: center;
      position: relative;
    }
    .preparation-title {
      @include pxRem(margin-bottom, 20);
      display: inline-block;
    }
    .preparation-image {
      display: block;
      width: 100%;
    }
    .prearation-title-longskirt {
      @include pxRem(margin-bottom, 20);
      width: 100%;
    }
  }
  .wrap-preparation-image {
    @include pxRem(padding, 20);
    @include display-flex;
    @include justify-content(center);
    @include flex-wrap(wrap);
    &:not(:last-child) {
      @include pxRem(margin-bottom, 20);
    }
    width: 100%;
    background-color: map-get($colors, white);
    img {
      @include pxRem(margin-bottom, 20);
    }
    ul {
      @include pxRem(font-size, 16);
      @include pxRem(padding-left, 16);
      width: 100%;
      li {
        p::before {
          @include pxRem(font-size, 16);
          @include pxRem(margin-left, -16);
          content: "・";
        }
      }
    }
    .uc-cloth {
      @include pxRem(width, 269);
      @include pxRem(height, 125);
    }
    .uc-standing {
      @include pxRem(width, 156);
      @include pxRem(height, 257);
    }
    .uc-longskirt {
      @include pxRem(width, 220);
      @include pxRem(height, 121);
    }
    .uc-cellphone {
      @include pxRem(width, 238);
      @include pxRem(height, 250);
    }
    .uc-desk {
      @include pxRem(width, 208);
      @include pxRem(height, 149);
    }
    .uc-picturing {
      @include pxRem(width, 218);
      @include pxRem(height, 169);
    }
    .uc-picturing-by-other {
      @include pxRem(width, 253);
      @include pxRem(height, 193);
    }
  }
}
