// medium: 768px,
// wide: 992px,
// large: 1024px,
// largest: 1280px,
// small <= 480px,
@include respond-below(small) {
  html {
    font-size: 16px;
  }
  @import "./small/introduction";
}
// height <= 674px,
@include respond-below-vertical(sc_h_674) {
  @import "./sc_h_674/sound-settings";
}
// height <= 620px,
@include respond-below-vertical(sc_h_620) {
  @import "./sc_h_620/react-webcam";
  @import "./sc_h_620/sound-settings";
}
// height <= 574px,
@include respond-below-vertical(sc_h_574) {
  @import "./sc_h_574/sound-settings";
}
// sc_374 <= 374px,
@include respond-below(sc_374) {
  html {
    font-size: 15px;
  }
  @import "./sc_374/react-webcam";
}
// sc_354 <= 354px,
@include respond-below(sc_354) {
  html {
    font-size: 14px;
  }
}
// sc_329 <= 329px,
@include respond-below(sc_329) {
  html {
    font-size: 13px;
  }
}
// x-small <= 320px
@include respond-below(x-small) {
  html {
    font-size: 13px;
  }
  @import "./x-small/introduction";
}
