.camera-error-box {
  position: fixed;
  z-index: 9;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 73px 24px 24px;
  .camera-error-wrapper {
    position: relative;
    background-color: map-get($colors, white);
    padding: 24px 16px 26px;
    .close-modal-btn {
      display: inline-flex;
      background: none;
      border: 0;
      position: absolute;
      right: 12px;
      top: 12px;
      padding: 4px;
      cursor: pointer;
    }
    .camera-title {
      padding: 24px 0;
      font-weight: 900;
      font-size: 24px;
      line-height: 35px;
      text-align: center;
      color: #2D4198;
    }
  }
  .camera-guide-list {
    margin-top: 5px;
    .guide-note-order {
      letter-spacing: 0.04em;
      font-weight: 700;
      font-size: 22px;
      line-height: 32px;
      margin-bottom: 16px;
    }
  }
  .guide-note-list {
    list-style-type: disc;
    margin: 16px 0 40px 27px;
    .guide-note-item {
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
      color: #000000;
    }
  }
}
