.body-size-input {
  @include pxRem(line-height, 24);
  .input-group {
    ul li:not(:last-child) {
      @include pxRem(margin-bottom, 16);
    }
  }
  .btn-submit {
    @include pxRem(margin-top, 40);
  }
  .size-input {
    position: relative;
    .custom-input {
      @include pxRem(max-height, 62);
      font-weight: 700;
      border: 1px solid map-get($colors, border) !important;
    }
    input {
      @include pxRem(padding-right, 40);
      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        font-weight: 400;
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        font-weight: 400;
      }
      &:-ms-input-placeholder {
        /* IE 10+ */
        font-weight: 400;
      }
      &:-moz-placeholder {
        /* Firefox 18- */
        font-weight: 400;
      }
    }
    &::after {
      @include pxRem(right, 18);
      position: absolute;
      top: 64%;
    }
    &-age::after {
      @include pxRem(font-size, 18);
      content: "歳";
      font-weight: 400;
    }
    &-height::after {
      @include pxRem(font-size, 18);
      content: "cm";
      font-weight: 400;
    }
    &-weight::after {
      @include pxRem(font-size, 18);
      content: "kg";
      font-weight: 400;
    }
    .ic-arrow-down {
      @include pxRem(height, 10);
      @include pxRem(width, 17);
      @include pxRem(right, 16);
      position: absolute;
      top: 80%;
    }
  }
  .middle-line {
    text-align: center;
    padding-top: 8px;
    p {
      position: relative;
      display: inline-block;
      font-weight: bold;
      &::before{
        @include pxRem(height, 1);
        @include pxRem(margin-top, -1);
        background: map-get($colors, border);
        position: absolute;
        content: '';
        top: 50%;
        right: -38vw;
        width: 36vw;
      }
      &::after{
        @include pxRem(height, 1);
        @include pxRem(margin-top, -1);
        background: map-get($colors, border);
        position: absolute;
        top: 50%;
        left: -38vw;
        content: '';
        width: 36vw;
      }
    }
  }
  .custom-checkbox,
  .note-text {
    @include pxRem(font-size, 18);
    @include pxRem(margin, 0 -4);
    @include pxRem(line-height, 27);
    color: map-get($colors, black-1);
  }
  .custom-checkbox {
    a {
      color: map-get($colors, blue);
      text-decoration: underline;
    }
  }
  .note-text {
    @include pxRem(padding-left, 28);
  }
}
