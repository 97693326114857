// Cursor
.cursor-pointer {
  cursor: pointer;
}
.cursor-default {
  cursor: default;
}
.pointer-event-none {
  pointer-events: none;
}
// Space
.p-2 {
  @include pxRem(padding, 8);
}
.px-2 {
  @include pxRem(padding-left, 8);
  @include pxRem(padding-right, 8);
}
.px-3 {
  @include pxRem(padding-left, 16);
  @include pxRem(padding-right, 16);
}
.py-2 {
  @include pxRem(padding-top, 8);
  @include pxRem(padding-bottom, 8);
}
.py-3 {
  @include pxRem(padding-top, 16);
  @include pxRem(padding-bottom, 16);
}
.py-4 {
  @include pxRem(padding-top, 24);
  @include pxRem(padding-bottom, 24);
}
.pt-2 {
  @include pxRem(padding-top, 10);
}
.pb-3 {
  @include pxRem(padding-bottom, 16);
}
.pt-4 {
  @include pxRem(padding-top, 20);
}
.pb-4 {
  @include pxRem(padding-bottom, 20);
}
.mt-5 {
  @include pxRem(margin-top, 32);
}
.mt-4 {
  @include pxRem(margin-top, 24);
}
.mt-3 {
  @include pxRem(margin-top, 16);
}
.mt-2 {
  @include pxRem(margin-top, 8);
}
.mt-1 {
  @include pxRem(margin-top, 4);
}
.mb-4 {
  @include pxRem(margin-bottom, 20 !important);
}
.mb-3 {
  @include pxRem(margin-bottom, 16);
}
.mb-2 {
  @include pxRem(margin-bottom, 8);
}
.mb-0 {
  margin-bottom: 0 !important;
}
.ml-3 {
  @include pxRem(margin-left, 16);
}
.ml-2 {
  @include pxRem(margin-left, 8);
}
// Grid
.d-none {
  display: none;
}
.d-none-imp {
  display: none !important;
}
.d-block {
  display: block;
}
.d-flex {
  @include display-flex;
}
.d-inline-block {
  display: inline-block;
}
.flex-centered {
  @extend .d-flex;
  @extend .align-items-center;
  @extend .justify-content-center;
}
.flex-no-wrap {
  @include flex-wrap(nowrap);
}
.align-items-center {
  align-items: center;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-between {
  justify-content: space-between;
}
.justify-content-around {
  justify-content: space-around;
}
.justify-content-evenly {
  justify-content: space-evenly;
}
// Text
.pre {
  white-space: pre !important;
}
.pre-wrap {
  white-space: pre-wrap;
}
.txt-center {
  text-align: center;
}
// Colors
.blue {
  color: map-get($colors, blue);
}
.yellow {
  color: map-get($colors, yellow) !important;
}
.orange {
  color: map-get($colors, orange) !important;
}
.txt-danger {
  color: map-get($colors, danger) !important;
}
// Background colors
.bg-organge-light {
  background-color: map-get($colors, orange-light);
}
.bg-transparent {
  background-color: transparent !important;
}
// Border
.border-1 {
  border: 1px solid map-get($colors, border);
}
.border-0 {
  border: 0 !important
}
.rounded-0 {
  border-radius: 0!important;
}
// Position
.middle {
  vertical-align: middle;
}
// Fonts
.font-weight-light {
  font-weight: 300 !important;
}
.font-weight-bold {
  font-weight: bold !important;
}
.fs-18 {
  @include pxRem(font-size, 18 !important);     
}
// Display
.hidden {
  visibility: hidden;
}
.h-0 {
  height: 0;
}
.h-auto {
  height: auto;
}
.max-height-100 {
  max-height: 100%;
}
.mx-initial {
  max-width: initial;
}
.w-100 {
  width: 100%;
}
.w-50 {
  width: 50%;
}
// Others
.link {
  color: map-get($colors, blue) !important;
  text-decoration: underline !important;
}
.is-hidden {
  display: none;
}
// Position
.is-relative {
  position: relative;
}
.is-absolute {
  position: absolute;
}
.is-fixed {
  position: fixed;
}
.position-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.object-fit-contain {
  object-fit: contain;
}
.object-fit-cover {
  object-fit: cover;
}
// === Background ===
.bg-blue {
  background-color: map-get($colors, blue);
}
.line-height-0 {
  line-height: 0 !important;
}
// === Flip image ===
.flip-img {
  transform: scaleX(-1) !important;
}
.overflow-hidden {
  overflow: hidden;
}
