@font-face {
  font-family: "A-OTF Gothic MB101 Pro";
  font-weight: 700;
  src: url("#{$assetPath}fonts/A-OTF-GothicMB101Pro-Bold.otf") format("opentype");
}
// @font-face {
// font-family: 'Noto Sans CJK JP';
//   font-family: 'Noto Sans';
//   src: url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap') format('opentype');
// }
