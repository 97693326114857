.Introduction {
  @include pxRem(line-height, 26);
  @include pxRem(margin-left, -4);
  @include pxRem(margin-right, -4);
  margin-top: 1rem;
  .intro-text-header {
    @include pxRem(font-size, 18);
    @include pxRem(line-height, 27);
    color: map-get($colors, black-1);
  }
  .intro-content {
    justify-content: space-between;
  }
  .flex {
    flex-wrap: inherit;
  }
  h1 {
    @include pxRem(margin-bottom, 16);
    color: map-get($colors, blue);
  }
  .intro {
    @include pxRem(margin-top, 32);
    .intro-item {
      .item-content {
        @include pxRem(margin-top, 16);
        @include pxRem(font-size, 18);
        @include pxRem(line-height, 27);
        font-weight: 500;
        color: map-get($colors, black-1);
      }
    }
  }
  .btn {
    @include pxRem(margin-top, 40);
  }
  a {
    color: map-get($colors, white);
  }
  .policy-group {
    position: relative;
    @include pxRem(margin-top, 40);
    @include pxRem(font-size, 18);
    @include pxRem(line-height, 27);
    color: map-get($colors, black-1);
    .label-container {
      display: inline-block;
      position: relative;
      padding-left: 32px;
      margin-bottom: 18px;
      cursor: pointer;
      font-size: 22px;
      user-select: none;
      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        &:checked ~ .checkmark {
          background-color: map-get($colors, blue);
          border: 0;
          &::after {
            display: block;
          }
        }
      }
      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 22px;
        width: 22px;
        border-radius: 2px;
        border: solid 1px map-get($colors, blue);
        background-color: map-get($colors, white);
        &::after {
          display: none;
          position: absolute;
          content: "";
          height: 8.98px;
          width: 12px;
          background-image: url("#{$assetPath}images/white_tick.svg");
          background-repeat: no-repeat;
          background-position: center;
          background-size: 100%;
          top: 7px;
          left: 5px;
        }
      }
    }
    .policy-link {
      border-bottom: solid 1px map-get($colors, blue);
      color: map-get($colors, blue);
      @include pxRem(line-height, 27);
    }
  }
  .intro-note {
    @include pxRem(margin, 16 0 40);
    @include pxRem(font-size, 18);
    @include pxRem(line-height, 27);
    font-weight: 400;
    color: map-get($colors, black-1);
    font-family: $font-family-default;
  }
}
