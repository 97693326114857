.error-box {
  position: fixed;
  z-index: 9;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  .error-content {
    @include pxRem(border-radius, 6);
    @include pxRem(padding, 20);
    background-color: map-get($colors, white);
    margin: auto;
    border: 2px solid map-get($colors, gray-1);
    width: 80%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .msg-title {
    @include pxRem(padding-bottom, 20);
    @include pxRem(border-bottom, 2 solid map-get($colors, gray));
  }
  .msg-content {
    @include pxRem(padding, 30 0);
    white-space: pre-wrap;
  }
}
