.bodygram-splash {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    @include pxRem(width, 134);
    object-fit: contain;
  }
}
