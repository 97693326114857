.lds-ring {
  display: inline-block;
  position: relative;
  &.blue div {
    @include pxRem(border, 8 solid map-get($colors, light-blue));
    border-color: map-get($colors, light-blue) map-get($colors, light-blue) transparent transparent;
  }
  &.orange div {
    @include pxRem(border, 8 solid map-get($colors, orange));
    border-color: map-get($colors, orange) map-get($colors, orange) transparent transparent;
  }
  &,
  & div {
    @include pxRem(height, 80);
    @include pxRem(width, 80);
  }
  div {
    @include pxRem(border, 8 solid map-get($colors, white));
    box-sizing: border-box;
    display: block;
    position: absolute;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: map-get($colors, white) transparent transparent transparent;
    &:nth-child(1) {
      animation-delay: -0.45s;
      border-color: map-get($colors, white);
    }
    &:nth-child(2) {
      animation-delay: -0.3s;
      border-color: map-get($colors, white);
    }
    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
