.custom-checkbox {
  @include pxRem(font-size, 16);
  @include pxRem(padding-left, 35);
  @include pxRem(margin-bottom, 12);
  @include pxRem(line-height, 22);
  display: block;
  position: relative;
  .custome-checkmark {
    @include pxRem(height, 22);
    @include pxRem(width, 22);
    @include pxRem(border-radius, 4);
    position: absolute;
    top: 0;
    left: 0;
    background-color: map-get($colors, white);
    border: 1px solid map-get($colors, border);
    &:after {
      @include pxRem(left, 7);
      @include pxRem(top, 3);
      @include pxRem(width, 4);
      @include pxRem(height, 8);
      content: "";
      position: absolute;
      display: none;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
  input {
    position: absolute;
    cursor: pointer;
    opacity: 0;
    height: 0;
    width: 0;
  }
  input:checked ~ .custome-checkmark {
    background-color: map-get($colors, blue);
  }
  input:checked ~ .custome-checkmark:after {
    display: block;
  }
}
