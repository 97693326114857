.header-page {
  @include pxRem(padding, 10 20);
  @include pxRem(max-height, 60);
  @include pxRem(min-height, 60);
  background-color: map-get($colors, white);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  z-index: 4;
  &.shadow {
    @include pxRem(box-shadow, 0 4 9.6 0.4 rgba(216, 216, 216, 0.57));
  }
  .header-logo {
    @include pxRem(height, 60);
  }
  .header-camera-logo {
    @include pxRem(padding, 10 0);
    .header-title {
      @include pxRem(line-height, 26);
      color: map-get($colors, blue);
    }
  }
  .btn-back {
    @include pxRem(height, 30);
    @include pxRem(width, 30);
    text-align: left;
    img {
      @include pxRem(height, 18);
      @include pxRem(width, 18);
    }
  }
  .btn-right {
    @include pxRem(height, 30);
    @include pxRem(font-size, 18);
  }
}

.header-page-camera {
  @include pxRem(padding, 10 20);
  @include pxRem(max-height, 60);
  @include pxRem(min-height, 60);
  @include pxRem(position, fixed);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  z-index: 4;
  .btn-back {
    @include pxRem(height, 50);
    @include pxRem(width, 50);
    @include pxRem(border-radius, 25);
    text-align: left;
    background-color: white;
    text-align: center;
    img {
      @include pxRem(height, 30);
      @include pxRem(width, 30);
    }
  }
  .btn-right {
    @include pxRem(height, 50);
    @include pxRem(font-size, 18);
    @include pxRem(border-radius, 25);
    @include pxRem(padding, 10);
    background-color: white;
    text-align: center;
  }
}
