// Defaul flex
.defaul-flex-row {
  @include display-flex;
  @include flex-direction(row);
  @include flex-wrap(wrap);
  @include justify-content(flex-start);
  @include align-items;
}
.defaul-flex-col {
  @include display-flex;
  @include flex-direction(column);
  @include flex-wrap(wrap);
  @include justify-content(space-between);
  @include align-items;
}
// Custom flex
.flex {
  @include display-flex;
  @include flex-wrap(wrap);
  @include justify-content(flex-start);
  @include align-items(stretch);
  @include align-content(space-between);
  &-row {
    @extend .flex;
    @include flex-direction(row) // flex-direction: row;
  }
  &-col {
    @extend .flex;
    @include flex-direction(column);
  }
}
