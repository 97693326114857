.btn {
	@include pxRem(border-radius, 4);
	@include pxRem(font-size, 18);
	@include pxRem(height, 52);
	font-weight: 700;
	font-family: $font-family-default;
	color: map-get($colors, white);
	border: 1px solid map-get($colors, blue);
	width: 100%;
	background-color: map-get($colors, blue);
	text-align: center;
	&.disabled {
		opacity: 0.5 !important;
	}
	&:hover {
		opacity: 1;
	}
	&-link {
		line-height: 1;
		color: map-get($colors, blue);
		background-color: transparent;
	}
}
.custom-input {
	@include pxRem(border-radius, 6);
	@include pxRem(font-size, 18);
	@include pxRem(margin-top, 4);
	@include pxRem(padding, 0 22);
	@include pxRem(height, 62);
	width: 100%;
	border: 2px solid map-get($colors, blue);
	&:focus {
		border: 1px solid map-get($colors, blue);
	}
}
