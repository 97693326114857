.bodygram-header {
  @include pxRem(margin-bottom, 32);
  &-title {
    @include pxRem(margin-bottom, 12);
    @include pxRem(font-size, 30);
    @include pxRem(line-height, 43);
    color: map-get($colors, blue);
    font-weight: 900;
  }
  &-desc {
    @include pxRem(font-size, 18);
    @include pxRem(line-height, 27);
    color: map-get($colors, black-1);
  }
}
