.categories {
  border: 1px solid map-get($colors, border);
  display: flex;
  flex-direction: column;
  &-item {
    @include pxRem(height, 44);
    @include pxRem(line-height, 44);
    display: flex;
    justify-content: center;
    position: relative;
    &:not(:last-child) {
      border-bottom: 1px solid map-get($colors, border);
    }
    &::after {
      @include pxRem(right, 16);
      top: 0;
      color: map-get($colors, blue);
      content: '>';
      display: block;
      position: absolute;
    }
  }
}
